import { ConsoleLogEvent, generateRandomPassword, isFedCMSupported, GoogleIdentityServices } from "../../utils";
import { ArrowLongLeftIcon, LockSolidOpenedIcon, ArrowLongRightIcon, ArrowLongDownIcon } from "../../icons";
import { GoogleSignUpInIcon, FacebookSignUpInIcon } from "../../icons";
import { guestCreateResource } from "../../services/farmApi";
import React, { useState, useRef, useEffect } from "react";
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { useTranslation } from "react-i18next";
import { loadFacebookSDK } from "../../utils";
import logo from "../../icons/gdf192.png";
import { Icon } from 'react-icons-kit';
import { jwtDecode } from "jwt-decode";
import { Spinner } from "../Spinner";
import "../../FacebookButton.css"; 
import "../../GoogleButton.css";


const Register = ({ onChangeDialog }) => {
  // for language translation
  const { t } = useTranslation();

  const [newFarm, setNewFarm] = useState({
    email:        "",
    farmName:     "",
    password:     "",
    passwordPair: "",
    firstName:    "",
    middleName:   "",
    lastName:     "",
  });

  const [regError, setRegError] = useState("");
  const [revealResp, setRevealResp] = useState("");
  const [shouldSpin, setShouldSpin] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordPair, setPasswordPair] = useState("");
  const [type, setType] = useState("password");
  const [typePair, setTypePair] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [iconPair, setIconPair] = useState(eyeOff);

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const repasswordInputRef = useRef(null);

  const [showEmailForm, setShowEmailForm] = useState(false); 
  const [showSignUpButton, setShowSignUpButton] = useState(true);

  const [googleSignInData, setGoogleSignInData] = useState(null); 
  const [signInError, setSignInError] = useState(null);
  const googleApiKey = process.env.REACT_APP_GOOGLE_GDF_PWA_FedCM_CLIEND_ID;
  const facebookApiKey = process.env.REACT_APP_FACEBOOK_GDF_PWA_AUTH_CLIEND_ID;
  const [fbReady, setFbReady] = useState(false);
  const [userData, setUserData] = useState(null);


  // ---------------- start the Google part ------------------------------
   
  const handleGoogleSignIn = (response) => {
    ConsoleLogEvent('info', 'Google Sign-In Raw Response:', response);

    if (response.error) {
      ConsoleLogEvent("error", "Google Sign-In Error:", response.error);
      setSignInError(response.error);
      setGoogleSignInData(null);
      return;
    }

    if (response.error) {
      ConsoleLogEvent("error", "Sign-In Error:", response.error);
      setSignInError(response.error);
      setGoogleSignInData(null);
      return;
    }

    try {
      const decoded = jwtDecode(response.credential);
      ConsoleLogEvent('info', 'Decoded Google JWT:', decoded);
  
      setGoogleSignInData({
        email: decoded.email,
        name: decoded.name,
        given_name: decoded.given_name,
        family_name: decoded.family_name,
        sub: decoded.sub,
        picture: decoded.picture,
        email_verified: decoded.email_verified,
        locale: decoded.locale,
        accessToken: response.credential,
        provider: "google",
      });
      setSignInError(null);
    } catch (err) {
      ConsoleLogEvent('error', 'Failed to decode JWT:', err);
      setSignInError("Failed to decode token.");
    }
  };

  const handleRegisterWithGoogleSignIn = async (farmData) => {
    ConsoleLogEvent('info', 'Registering farmData:', farmData);
  
    setShouldSpin(true);
    try {
      const regResp = await guestCreateResource(
        process.env.REACT_APP_FARM_REGISTER,
        {
          farm_name: farmData.farmName,
          email: farmData.email,
          password: farmData.password,
          first_name: farmData.firstName,
          middle_name: farmData.middleName,
          last_name: farmData.lastName,
        },
        t
      );
  
      if (regResp.success) {
        setRegError("");
        setRevealResp(regResp.message);
      } else {
        const errorMsg = regResp.code === 402
          ? t('login_register_page.register_farm_taken_error')
          : regResp.code === 500
          ? t('login_register_page.register_internal_server_error')
          : regResp.message;
  
        setRegError(errorMsg);
      }
    } catch (error) {
      ConsoleLogEvent("error", "Registration failed:", error);
      setRegError(t('login_register_page.register_error'));
    } finally {
      setShouldSpin(false);
    }
  };
  
  useEffect(() => {
    const registerAndSignOut = async () => {
      if (!googleSignInData) return;
  
      // Prepare the updated newFarm object directly
      const updatedFarm = {
        ...newFarm,
        email: googleSignInData.email,
        password: generateRandomPassword(),
        firstName: googleSignInData.given_name || googleSignInData.name,
        middleName: googleSignInData.given_name || "",
        lastName: googleSignInData.family_name,
      };
  
      // Log the updated newFarm for debugging
      ConsoleLogEvent("info", "Prepared updatedFarm data for registration:", updatedFarm);
  
      // Update the state
      setNewFarm(updatedFarm);
  
      try {
        // Pass the updatedFarm directly to handleRegisterWithGoogleSignIn
        await handleRegisterWithGoogleSignIn(updatedFarm);
        await handleGoogleSignOut();
      } catch (error) {
        ConsoleLogEvent("error", "Error during registration or sign-out:", error);
      }
    };
  
    registerAndSignOut();
  }, [googleSignInData]);

  const handleGoogleSignOut = async () => {
    try {
      if (googleSignInData?.email) {
        if (window.google?.accounts?.id) {
          window.google.accounts.id.revoke(googleSignInData.email, () =>
            ConsoleLogEvent("info", "Google consent revoked.")
          );
        }
      }
      setGoogleSignInData(null);
      setSignInError(null);
    } catch (error) {
      ConsoleLogEvent("error", "Failed to sign out:", error);
    }
  };

  // Initialize Google Identity Services
  useEffect(() => {
    GoogleIdentityServices()
      .then(() => {
        if (!window.google?.accounts?.id) {
          ConsoleLogEvent("warn", "Google Identity Services is not available");
          return;
        }

        // Initialize Google Sign-In with the handleGoogleSignIn callback
        window.google.accounts.id.initialize({
          client_id: googleApiKey,
          callback: handleGoogleSignIn,
          use_fedcm_for_prompt: true,
        });
      })
      .catch((error) => {
        ConsoleLogEvent("error", "Google Identity Services failed to load:", error);
      });
  }, [googleApiKey]);

// ---------------- end the Google part ------------------------------




  // ****************** start the Facebook Part ******************

  useEffect(() => {
    // Load the Facebook SDK when the component mounts
    loadFacebookSDK(facebookApiKey)
      .then(() => {
        ConsoleLogEvent('info', 'Facebook SDK is ready');
        setFbReady(true);

        // Check if the user is already logged in
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            ConsoleLogEvent('info', 'User already logged in:', response);
            fetchFacebookUserData(response.authResponse);
          } else {
            ConsoleLogEvent('warn', 'User not logged in or session expired:', response);
          }
        });
      })
      .catch((error) => {
        ConsoleLogEvent('error', 'Error loading Facebook SDK:', error);
      });
  }, []);


  const fetchFacebookUserData = (authResponse) => {
    const { accessToken, userID } = authResponse;
    window.FB.api('/me', { fields: 'email,first_name,last_name' }, (userData) => {
      ConsoleLogEvent('info', 'Fetched Facebook User Data:', userData);
      setUserData(userData);
      // Send userData.email, userData.first_name, and userData.last_name to your backend with farm name
      // handleFacebookLogin();
    });
  };

  const handleFacebookSignUp = () => {
    if (!window.FB) {
      ConsoleLogEvent('error', 'Facebook SDK is not loaded');
      return;
    }

    window.FB.login((response) => {
      if (response.status === 'connected') {
        ConsoleLogEvent('info', 'User successfully logged in:', response);
        fetchFacebookUserData(response.authResponse);
      } else {
        ConsoleLogEvent('warn', 'User cancelled login or did not fully authorize:', response);
      }
    }, { scope: 'public_profile,email' });
  };

  // This effect will run once userData is updated
  useEffect(() => {
    if (userData) {
      handleRegisterWithFacebookSignUp(); // Call handleRegisterWithFacebookSignIn once userData is set
    }
  }, [userData]); // Triggered whenever userData changes

  const handleRegisterWithFacebookSignUp =  async () => {
    ConsoleLogEvent("info", "Facebook register has now started with user data:", userData);

    const randomPassword = generateRandomPassword();

    setShouldSpin(true);
    try {
      const regResp = await guestCreateResource(
        process.env.REACT_APP_FARM_REGISTER,
        {
          farm_name: newFarm.farmName,
          email: userData.email,
          password: randomPassword,
          first_name: userData.first_name,
          middle_name: '',
          last_name: userData.last_name,
        },
        t
      );
  
      if (regResp.success) {
        setRegError("");
        setRevealResp(regResp.message);

        // Once login is successful, logout from Facebook
        handleFacebookLogout(); // Call logout after login is successful

      } else {
        const errorMsg = regResp.code === 402
          ? t('login_register_page.register_farm_taken_error')
          : regResp.code === 500
          ? t('login_register_page.register_internal_server_error')
          : regResp.message;
  
        setRegError(errorMsg);
      }
    } catch (error) {
      ConsoleLogEvent("error", "Registration failed:", error);
      setRegError(t('login_register_page.register_internal_server_error'));
    } finally {
      setShouldSpin(false);
    }
  };

  const handleFacebookLogout = () => {
    // log out user from facebook on this app as is not needed anymore
    if (!window.FB) {
      ConsoleLogEvent('error', 'Facebook SDK is not loaded');
      return;
    }
  
    window.FB.logout((response) => {
      if (response.status !== 'connected') {
        ConsoleLogEvent('info', 'User successfully logged out:', response);
        // Clear user-related data from your app
        setUserData(null);
      } else {
        ConsoleLogEvent('warn', 'User is still connected:', response);
      }
    });
  };

  // ****************** end the Facebook Part ******************



  const handleFarmName = (e) => {
    setNewFarm((farm) => ({ ...farm, farmName: e.target.value }));
  };

  const handleEmail = (e) => {
    setNewFarm((farm) => ({ ...farm, email: e.target.value }));
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setNewFarm((farm) => ({ ...farm, password: e.target.value }));
  };

  const handlePasswordPair = (e) => {
    setPasswordPair(e.target.value);
    setNewFarm((farm) => ({ ...farm, passwordPair: e.target.value }));
  };

  // Having tricked Chrome browser with readonly email field,
  // so that Chrome disables form input autocompletes, we need to
  // to remove readonly onfocus
  const handleOnFocus = (refType) => {
    if (refType === "email") {
      emailInputRef.current.removeAttribute("readonly");
    } else if (refType === "password") {
      passwordInputRef.current.removeAttribute("readonly");
    } else if (refType === "repassword") {
      repasswordInputRef.current.removeAttribute("readonly");
    }
  };  

  const handleSignUpWithEmailClick = (e) => {
      e.preventDefault();
    setShowEmailForm(true);
    setShowSignUpButton(false);
  };

  const handleNext = () => {
    if (!newFarm.farmName.trim()) {
      setRegError(t('error_message.farm_name_required'));
    } else {
      setRegError("");
      setShowForm(true);
    }
  };

  const handleRegisterWithEmailSignIn = async (e) => {
    e.preventDefault();

    ConsoleLogEvent('info', 'handle register is called now using email and formState: ', newFarm);
    
    // Extract the part of the email before the @ symbol
    const nameFromEmail = newFarm.email.split("@")[0];

    if (newFarm.password !== newFarm.passwordPair) {
      setRegError(t("error_message.mismatch_password"));
    } else {
      setShouldSpin(true);
      const regResp = await guestCreateResource(
        process.env.REACT_APP_FARM_REGISTER,
        {
          farm_name: newFarm.farmName, 
          email: newFarm.email,
          password: newFarm.password,
          first_name: nameFromEmail, 
          middle_name: nameFromEmail, 
          last_name: nameFromEmail, 

        },
        t
      );
      if (regResp.success) {
        setRegError("");
        setShouldSpin(false);
        setRevealResp(regResp.message);
      } else {
        if(regResp?.code === 402){
          // "The Farm name or Email are already taken, Choose another."
          const farmTakenErrorMessage = t('login_register_page.register_farm_taken_error');
          ConsoleLogEvent('error', 'The Farm name or Email are already taken, Choose another: ', regResp);
          setRegError(farmTakenErrorMessage);
        }else{
          setRegError(regResp?.message);
        }
        setShouldSpin(false);
        ConsoleLogEvent('error', 'error response from the server with response message: ', regResp);
      }
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
      if (typePair !== "text") {
        setTypePair("password");
        setIconPair(eyeOff);
      }
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleTogglePair = () => {
    if (typePair === "password") {
      setIconPair(eye);
      setTypePair("text");
      if (type !== "text") {
        setType("password");
        setIcon(eyeOff);
      }
    } else {
      setIconPair(eyeOff);
      setTypePair("password");
    }
  };

  const handleLogin = () => {
    onChangeDialog("landing");
  };

  return (
    <>
      {/* Navigation to login */}
      <div className="w-full flex justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="flex justify-start pt-2">
            <div
              className="flex gap-2 mt-5 cursor-pointer border border-gray-300 rounded-full pl-2 pr-4 py-1"
              onClick={handleLogin}
            >
              <ArrowLongLeftIcon />
              <span>{t("buttons_navigation.hyperlink_login")}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex min-h-full items-center justify-center py-1 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <div className="flex justify-center">
              <img
                src={logo}
                className="w-[80px] h-auto"
                alt="Logo"
              />
            </div>

            {/** form title */}
            <div className="text-gray-600  text-s p-0">
              {t('login_register_page.register_farm_notice')}
            </div> 
            <h2 className="flex min-h-full items-center justify-center text-gray-400 text-s p-0">
              {regError || signInError ? (
                <span className="text-red-400 text-sm">
                  {regError || signInError}
                </span>
              ) : (
                ""
              )}
            </h2>

          </div>

          {!showForm ? (
            <div className="space-y-6">
              <div>
                <label htmlFor="farmName" className="sr-only">
                  {t("login_register_page.label_farm_name")}
                </label>
                <input
                  id="farmName"
                  name="farmName"
                  type="text"
                  onChange={handleFarmName}
                  required
                  className="relative block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-500"
                  placeholder={t("login_register_page.enter_farm_name")}
                />
              </div>
              <button
                type="button"
                onClick={handleNext}
                className="group relative flex w-full justify-center rounded-md bg-slate-500 py-2 px-4 text-sm text-white hover:bg-slate-600"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <ArrowLongRightIcon
                    className="h-5 w-5 text-white group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                  {t('buttons_navigation.button_next_to_complete')}
              </button>
            </div>
          ) : shouldSpin ? (
            <Spinner />
          ) : revealResp ? (
            <div>{revealResp}</div>
          ) : (

            <form 
              className="mt-8 space-y-6" 
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              >
              <div className="space-y-4">

                {/** start of this part which open correctly as the first part */}
                <label htmlFor="password" className="sr-only">
                      {t('login_register_page.label_farm_name')}
                </label>                
                <input
                  id="farmName"
                  name="farmName"
                  type="text"
                  value={newFarm.farmName}
                  readOnly
                  className="relative block w-full rounded-md border-gray-300 px-3 py-2 bg-gray-100"
                />
                <div className="flex justify-center  text-gray-600  text-s p-0"> {t('login_register_page.login_registration_option')}</div>
                {/** end of this part which open correctly as the first part */}

                {/** Button to show the email form */}
                {showSignUpButton && (
                  <button 
                    onClick={handleSignUpWithEmailClick} 
                    className="group relative flex w-full justify-center rounded-md bg-slate-500 py-2 px-4 text-sm text-white hover:bg-slate-600"
                  >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <ArrowLongDownIcon
                          className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                          aria-hidden="true"
                        />
                      </span>
                       {t('buttons_navigation.button_sign_up_with_email')} 
                  </button>
                )}

                {/** start of part XX */}
                {showEmailForm && (
                  <div>
                    <label htmlFor="email" className="sr-only">
                      {t('login_register_page.label_email_address')}
                    </label>
                    <div className="mb-4 flex"> 
                      <input
                        ref={emailInputRef}
                        readOnly={true}
                        id="email"
                        name="email"
                        type="email"
                        onChange={handleEmail}
                        onFocus={() => {
                          handleOnFocus("email");
                        }}
                        autoComplete="off"
                        required={true}
                        className="relative block w-full rounded-md border-gray-300 px-3 py-2"
                        placeholder={t("login_register_page.enter_email_address")}
                      />
                    </div>
                    <label htmlFor="password" className="sr-only">
                      {t('login_register_page.label_password')}
                    </label>  
                    <div className="mb-4 flex">              
                      <input
                        ref={passwordInputRef}
                        readOnly={true}
                        id="password"
                        name="password"
                        type={type}
                        onChange={handlePassword}
                        onFocus={() => {
                          handleOnFocus("password");
                        }}
                        autoComplete="off"
                        required={true}
                        className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                        placeholder={t("login_register_page.enter_password")}
                      />
                      <span className="flex justify-around items-center" onClick={handleToggle}>
                          <Icon className="absolute mr-10" icon={icon} size={25}/>
                      </span>
                    </div>
                    <label htmlFor="password" className="sr-only">
                          {t('login_register_page.label_retype_password')}
                    </label>
                    <div className="mb-4 flex">
                      <input
                        ref={repasswordInputRef}
                        readOnly={true}
                        id="passwordPair"
                        name="passwordPair"
                        type={typePair}
                        onChange={handlePasswordPair}
                        onFocus={() => {
                          handleOnFocus("repassword");
                        }}
                        autoComplete="off"
                        required={true}
                        className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                        placeholder={t("login_register_page.re_type_password")}
                      />
                      <span className="flex justify-around items-center" onClick={handleTogglePair}>
                          <Icon className="absolute mr-10" icon={iconPair} size={25}/>
                      </span> 
                    </div> 
                    <button
                      type="submit"
                      onClick={handleRegisterWithEmailSignIn}
                      className="group relative flex w-full justify-center rounded-md bg-slate-500 py-2 px-4 text-sm text-white hover:bg-slate-600"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockSolidOpenedIcon
                          className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                          aria-hidden="true"
                        />
                      </span>
                      {t("buttons_navigation.button_sign_up")}
                    </button>
                  </div>
                )}
                {/** End of part XX */}
              
                {/* <div className="flex justify-center text-gray-600  text-s p-0">{t('login_register_page.login_or_mark')}</div> */}


                {/* Google Sign-In Button */}
                <button
                  onClick={() => {
                    if (isFedCMSupported()) {
                      window.google.accounts.id.prompt();
                    } else {
                      // see what you can do here, great to make this meaningful
                      ConsoleLogEvent('warn', 'Your browser does not support FedCM. Please update to the latest version.');
                    }
                  }}
                  className="gsi-material-button"
                >
                  <div className="gsi-material-button-content-wrapper">
                    <div className="gsi-material-button-icon">
                      {/* SVG for Google logo */}
                      <GoogleSignUpInIcon />
                    </div>
                    <span className="gsi-material-button-contents">
                      {/* Use translation for the button text */}
                      {t("buttons_navigation.button_sign_up_with_google")}
                    </span>
                  </div>
                </button>


                {/* <div className="flex justify-center text-gray-600  text-s p-0">{t('login_register_page.login_or_mark')}</div> */}

                {/* Faceeok Sign-Up Button */}          

                {fbReady ? (
                  <button onClick={handleFacebookSignUp} className="fbi-material-button">
                      <div className="fbi-material-button-state"></div>
                        <div className="fbi-material-button-content-wrapper">
                          <div className="fbi-material-button-icon">
                            <FacebookSignUpInIcon />
                          </div>
                            {/* Use translation for the button text */}
                            <span className="fbi-material-button-contents">
                              {t('buttons_navigation.button_continue_with_facebook')}
                            </span>
                            <span style={{ display: "none" }}>
                              {t('buttons_navigation.button_continue_with_facebook')}
                            </span>
                        </div>
                      
                  </button>
                ) : (
                  <p>{t('login_register_page.facebook_sdk_loading')}</p>
                )}




              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export { Register };
