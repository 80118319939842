import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Chart } from "react-google-charts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Footer,
  SelectInputV2,
  TableDAtaView,
  EditDelTableDataView,
  Spinner,
} from "../../components";
import { MilkingForm } from "../Milking";
import "./reportStyle.css";

import { useHandleSubmit, useEnaDisableStyle } from "../../hooks";
import { useEnv } from "../../contexts";
import {
  getResource,
  deleteResource,
  updateResource,
} from "../../services/farmApi";

import { useTranslation } from "react-i18next";

function MilkingsReport() {
  // for language translation
  const { t } = useTranslation();

  const showToast = (message, type) => {
    toast[type](message, {
      position: "top-center", 
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  
  const [isSearching, setIsSearching] = useState(false);
  const [milkingRecord, setMilkingRecord] = useState({});
  const [selectedId, setSelectedId] = useState(0);
  const [showMilkingForm, setShowMilkingForm] = useState(false);
  const [footerType, setFooterType] = useState("nav");
  const [fetchCount, setFetchCount] = useState(0);
  const [milkingViewData, setMilkingViewData] = useState([]); // Ensure this is defined
  const [milkingData, setMilkingData] = useState([]); // Ensure this is defined
  const [report, setReport] = useState({filter: t("search_page.select_report"),}); 

  // Influences how table is displayed
  const [tableSettings, setTableSettings] = useState({
    idUsage: 1,
    useSerial: true,
  });
  const initNotice = useRef({
    msg: "",
    showNotice: false,
    succ: false,
    status: null,
    ver: null,
  });
  const [createNotice, setCreateNotice] = useState(initNotice.current);
  const [fetchNotice, setFetchNotice] = useState(initNotice.current);
  
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  const { handleGetResource, handleSubmit } = useHandleSubmit();
  const { envState, setEnvState } = useEnv(); // Get Environment context

  // Filter Select options
  const reportOptions = useRef([
    ["default", t("search_page.select_report")],            // default = nothing backend 
    ["today", t("page_elements.today")],                    // today == today
    ["month", t("page_elements.30_days")],                  // month == 30 days
    ["quarter", t("page_elements.90_days")],                // quarter == 90 days
    ["summary", t("table_formatter.table_weekly_total")],   // summary == weekly total
    ["trend", t("page_elements.trend")],                    // trend == trend
    ["twoday", t("page_elements.twoday")],                  // twoday == Edit Delete
  ]);
  const textInputStyle = useEnaDisableStyle();

  // Handle Radio's input record selection on Edit and Delete table
  const handleOnSelectId = (inputKey, inputValue) => {
    // Check if the radio button is clicked
    if (inputKey === 'selectedId') {
    } else {
        // When the row is clicked, swap inputKey and inputValue
        inputValue = inputKey;
        inputKey = 'selectedId';
    }

    // Always set inputKey to 'selectedId'
    inputKey = 'selectedId';

    setSelectedId(inputValue);
  };

  /**
   * Notifiy the App of this location, provide Header Nav links
   */
  useEffect(() => {
    setEnvState((state) => ({
      ...state,
      whereAbout: t("navigation_element.nav_item_title_milking_report"),
      backPath: "/fe/reports",
      homePath: "/fe",
    }));

    return () => {
      setEnvState((state) => ({
        ...state,
        whereAbout: "",
        backPath: "",
        homePath: "",
      }));
    };
    // eslint-disable-next-line
  }, [footerType]);

  // Handle Select input change
  const handleReportChange = (inputKey, inputValue) => {
    setReport({ [inputKey]: inputValue });
  };

  // Fetch report
  useLayoutEffect(() => {
    // Updated handleFilterChange to clear milkingViewData before fetching
    const handleFilterChange = (filter, idUsage = 1, useSerial = true) => {
      setMilkingViewData([]); // Clear milkingViewData to prevent accumulation
      getMilkingReport(`/?filter=${filter}`);
      setTableSettings({ idUsage, useSerial });
    };

    // Handle clear report filter
    const handleReset = () => {
      milkingViewData.length > 0 && setMilkingViewData([]);
      milkingData.length > 0 && setMilkingData([]);
    };

    const getMilkingReport = (reportQuery) => {

      handleGetResource(
        null,
        getResource,
        true, //envState.online,
        process.env.REACT_APP_MILKINGS + reportQuery,
        setCreateNotice,
        null,
        setMilkingData,
        setIsSearching,
        false // explicitly expecting a single model?
      );
    };
    switch (report.filter) {
      case reportOptions.current[1][0]:
      case reportOptions.current[2][0]:
      case reportOptions.current[3][0]:
      case reportOptions.current[4][0]:
      case reportOptions.current[5][0]:
      case reportOptions.current[6][0]:
        handleFilterChange(report.filter);
        break;
      default:
        handleReset();
        break;
    }

    // switch (report.filter) {
    //   case reportOptions.current[1][0]:
    //     handleFilterChange(reportOptions.current[1][0]);
    //     break;
    //   case reportOptions.current[2][0]:
    //     handleFilterChange(reportOptions.current[2][0]);
    //     break;
    //   case reportOptions.current[3][0]:
    //     handleFilterChange(reportOptions.current[3][0]);
    //     break;
    //   case reportOptions.current[4][0]:
    //     handleFilterChange(reportOptions.current[4][0]);
    //     setTableSettings((x) => ({ ...x, idUsage: 0 }));
    //     break;
    //   case reportOptions.current[5][0]:
    //     handleFilterChange(reportOptions.current[5][0]);
    //     break;  
    //   case reportOptions.current[6][0]:
    //     handleFilterChange(reportOptions.current[6][0]);
    //     break;     
    //   default:
    //     handleReset();
    //     break;
    // }
    // eslint-disable-next-line
  }, [report, fetchCount]);


  // Display report on Trend
  useLayoutEffect(() => {
    if (milkingData.length > 0) {
      if (report.filter === "trend") {
        // Branching out to show chart
        // Extract categories (x-axis labels), AM, Noon and PM litres

        const transformedArray = milkingData.map(({date, am_total, noon_total, pm_total}) => {
          return [
            date,
            am_total || 0,
            noon_total || 0,
            pm_total || 0
          ];
        });

        const dataNew = [
          ["Date", "AM", "Noon", "PM"],
          ...transformedArray,
        ];

        const optionsNew = {
          title: "Daily AM, Noon, PM Milk",
          vAxis: { title: "Litre" },
          hAxis: { title: "Date" },
          seriesType: "bars",
          series: { 5: { type: "line" } },
        };

        setChartData(dataNew);
        setChartOptions(optionsNew);
      } else {
        setMilkingViewData(milkingData);
      }
    } else {
      setMilkingViewData([]);
    }
    // eslint-disable-next-line
  }, [milkingData]);



  // handle hide/show edit and delete controls using twoday
  useLayoutEffect(() => {
    if (createNotice.succ && report.filter === "twoday") {
      setFooterType("editDelete");
    } else {
      setFooterType("nav");
    }
  }, [createNotice.succ, report.filter]);

  // Added a confirmation dialog or modal before proceeding with the delete operation
  const handleDelete = (e) => {
    e.preventDefault();
    if (selectedId) {
      // Show confirmation dialog
      const isConfirmed = window.confirm(
        t("validation_messages.confirm_deletion")
      );

      if (isConfirmed) {
        const deleteMilkingUrl =
          process.env.REACT_APP_MILKINGS + "/" + selectedId;

        // Delete the selected Record
        handleSubmit(
          e,
          null,
          deleteResource,
          envState.online,
          deleteMilkingUrl,
          () => {},
          (notice) => {
            setCreateNotice({ ...notice, showNotice: false });
          },
          () => {
            setFetchCount((x) => x + 1);
          },
          (event) => {
          }
        );
      } else {
        // console.log("Deletion cancelled.");
      }
    } else {
      // inform user that nothing has been selected
        // info, error, success are possible options
        showToast(t("validation_messages.select_record_first"), "info");  
    }
  };

  // Fetch a record to edit
  const handleEdit = (e) => {
    e.preventDefault();
    if (selectedId) {
      // e.preventDefault();
      const fetchMilkingRecordUrl =
        process.env.REACT_APP_MILKINGS + "/" + selectedId;
      // Delete the selected Record
      handleGetResource(
        null,
        getResource,
        true, //envState.online,
        fetchMilkingRecordUrl,
        setFetchNotice,
        null,
        setMilkingRecord,
        setIsSearching,
        false // explicitly expecting a single model?
      );
    } else {
      // inform user that nothing has been selected
      // info, error, success are possible options
      showToast(t("validation_messages.select_record_first"), "info");  
    }
  };

  // Handle Cancel on Edit page
  const handleEditCancel = (e) => {
    e.preventDefault();
    setShowMilkingForm(false);
    setFooterType("editDelete");
  };

  // Handle updating edited record
  const handleEditUpdate = (e, updatedData, milkingID) => {
    e.preventDefault();
    if (milkingID) {
      // e.preventDefault();
      const updateMilkingUrl = process.env.REACT_APP_MILKINGS + "/" + milkingID;
      // Delete the selected Record
      handleSubmit(
        e,
        updatedData,
        updateResource,
        envState.online,
        updateMilkingUrl,
        () => {},
        (notice) => {
          setCreateNotice({ ...notice, showNotice: false });
        },
        () => {
          setFetchCount((x) => x + 1);
          setShowMilkingForm(false);
        },
        (event) => {
          // console.log("update request queued!");
        }
      );
    } else {
      // console.log("nothing selected - see!>>", selectedId);
    }
  }; 

  // Monitor for availability of requesed milking record for editing
  useEffect(() => {
    if (milkingRecord?.id) {
      setShowMilkingForm(true);
      setFooterType("edit");
    }
  }, [milkingRecord]);


  // if (!showMilkingForm) {
  //   return (
  //     <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
  //       <div className="col-span-2 flex flex-col justify-center items-center">
  //         <form autoComplete="off" className="w-full">
  //           <div className="py-5">
  //             <div className="grid grid-cols-6 lg:grid-cols-5">
  //               <div className="hidden lg:block lg:col-span-1">
  //                 {/*spacer*/}
  //               </div>
  //               {/* Report - select */}
  //               <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
  //                 <div className="w-[400px]">
  //                   <SelectInputV2
  //                     focus={false}
  //                     inputName="filter"
  //                     inputId="report"
  //                     inputStyle={textInputStyle.current.enabledInput}
  //                     inputValue={report.filter}
  //                     handleChange={handleReportChange}
  //                     choices={reportOptions.current}
  //                   />
  //                 </div>
  //               </div>
  //               <div className="hidden lg:block lg:col-span-1">
  //                 {/*spacer*/}
  //               </div>
  //             </div>

  //             {isSearching && (
  //               <div className="grid grid-cols-6 lg:grid-cols-5">
  //                 <div className="col-span-6 lg:col-span-5 flex justify-center p-2">
  //                   <Spinner />
  //                 </div>
  //               </div>
  //             )}
  //               {createNotice.succ ? (
  //                 report.filter === "trend" ? (
  //                   <div className="grid grid-cols-12 mt-6">
  //                     <div className="col-span-1">{/*spacer*/}</div>
  //                     <div className="col-span-10 border-[2px] border-gray-200 rounded-lg">
  //                       <div className="w-full flex justify-center p-2">
  //                         <div className="overflow-x-auto frame">
  //                           <div className="w-full">
  //                             <Chart
  //                               chartType="ComboChart"
  //                               width="100%"
  //                               height="360px"
  //                               data={chartData}
  //                               options={chartOptions}
  //                             />
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="col-span-1">{/*spacer*/}</div>
  //                   </div>
  //                 ) : (
  //                 <div className="grid grid-cols-6 lg:grid-cols-5">
  //                   <div className="hidden lg:block lg:col-span-1">
  //                     {/*spacer*/}
  //                   </div> 
  //                   <div className="col-span-6 lg:col-span-3">
  //                     <div className="col-span-6 lg:col-span-3">
  //                       <div className="w-full flex justify-center p-2">

  //                         {["twoday", "summary", "today", "month", "quarter"].includes(report.filter) ? (
  //                         <TableDAtaView
  //                           tableData={milkingViewData}
  //                           idUsage={tableSettings.idUsage}
  //                           useSerial={tableSettings.useSerial}
  //                           mapHash={{
  //                             "#": "#",
  //                             am_volume: t("table_formatter.table_am"),
  //                             pm_volume: t("table_formatter.table_pm"),
  //                             noon_volume: t("table_formatter.table_noon"),
  //                             date: t("table_formatter.table_date"),
  //                             total: t("milking_page.total"),
  //                             session_type: t("table_formatter.table_session"),
  //                             cattle_id: t("milking_page.min"),
  //                             cattle_name: t("cattle_page.name"),
  //                             volume: t("table_formatter.table_volume"),
  //                             min_vol: t("milking_page.min"),
  //                             max_vol: t("milking_page.max"),
  //                             avg_vol: t("milking_page.average"),
  //                             mon: t("table_formatter.table_month"), 
  //                             week1: t("table_formatter.table_1st_wk"), 
  //                             week2: t("table_formatter.table_2nd_wk"), 
  //                             week3: t("table_formatter.table_3rd_wk"), 
  //                             week4: t("table_formatter.table_4th_wk"), 
  //                             total: t("table_formatter.table_total"), 
  //                           }}
  //                         />
  //                       ) : report.filter === "twoday" ? (
  //                         <EditDelTableDataView
  //                           tableData={milkingViewData}
  //                           idUsage={0}
  //                           useSerial={tableSettings.useSerial}
  //                           mapHash={{
  //                             "#": "#",
  //                             am_volume: t("table_formatter.table_am"),
  //                             pm_volume: t("table_formatter.table_pm"),
  //                             noon_volume: t("table_formatter.table_noon"),
  //                             date: t("table_formatter.table_date"),
  //                             total: t("milking_page.total"),
  //                             session_type: t("table_formatter.table_session"),
  //                             cattle_id: t("table_formatter.table_tag"),
  //                             cattle_name: t("cattle_page.name"),
  //                             volume: t("table_formatter.table_volume"),
  //                             min_vol: t("milking_page.min"),
  //                             max_vol: t("milking_page.max"),
  //                             avg_vol: t("milking_page.average"),
  //                           }}
  //                           selectedId={selectedId}
  //                           onSelectId={handleOnSelectId}
  //                         />
  //                       ) : null /* Optionally handle any other specific cases here */ }
  //                       </div>
  //                     </div>

  //                   </div>
  //                   <div className="hidden lg:block lg:col-span-1">
  //                     {/*spacer*/}
  //                   </div>
  //                 </div>
  //               )
  //             ) : createNotice.status === 404 ? (
  //               <div className="grid grid-cols-6 lg:grid-cols-5">
  //                 <div className="hidden lg:block lg:col-span-1">
  //                   {/*spacer*/}
  //                 </div>
  //                 <div className="col-span-6 lg:col-span-3">
  //                   <div className="w-full flex justify-center py-2">
  //                     <span>
  //                       {t("user_handle_notice.no_data_matching_criteria")}
  //                     </span>
  //                   </div>
  //                 </div>
  //                 <div className="hidden lg:block lg:col-span-1">
  //                   {/*spacer*/}
  //                 </div>
  //               </div>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //           <Footer
  //             respNotice={createNotice}
  //             setRespNotice={setCreateNotice}
  //             backLink="/fe/reports"
  //             footerType={footerType}
  //             onDelete={handleDelete}
  //             onEdit={handleEdit}
  //           />
  //           {/*</div>*/}
  //         </form>
  //       </div>

  //       <div>
  //         {/* Toast Container */}
  //         <ToastContainer
  //           position="top-center"
  //           autoClose={5000}
  //           hideProgressBar={false}
  //           closeOnClick
  //           pauseOnHover
  //           draggable
  //           pauseOnFocusLoss
  //           theme="light"
  //         />
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div className="mt-[80px] py-4 md:px-8">
  //       <MilkingForm
  //         milking={milkingRecord}
  //         formMode={"edit"}
  //         onCancel={handleEditCancel}
  //         onEdit={handleEditUpdate}
  //       />
  //     </div>
  //   );
  // }

  if (!showMilkingForm) {
    return (
      <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
        <div className="col-span-2 flex flex-col justify-center items-center">
          <form autoComplete="off" className="w-full">
            <div className="py-5">
              <div className="grid grid-cols-6 lg:grid-cols-5">
                <div className="hidden lg:block lg:col-span-1">
                  {/*spacer*/}
                </div>
                {/* Report - select */}
                <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
                  <div className="w-[400px]">
                    <SelectInputV2
                      focus={false}
                      inputName="filter"
                      inputId="report"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={report.filter}
                      handleChange={handleReportChange}
                      choices={reportOptions.current}
                    />
                  </div>
                </div>
                <div className="hidden lg:block lg:col-span-1">
                  {/*spacer*/}
                </div>
              </div>
  
              {isSearching && (
                <div className="grid grid-cols-6 lg:grid-cols-5">
                  <div className="col-span-6 lg:col-span-5 flex justify-center p-2">
                    <Spinner />
                  </div>
                </div>
              )}
              {createNotice.succ ? (
                report.filter === "trend" ? (
                  <div className="grid grid-cols-12 mt-6">
                    <div className="col-span-1">{/*spacer*/}</div>
                    <div className="col-span-10 border-[2px] border-gray-200 rounded-lg">
                      <div className="w-full flex justify-center p-2">
                        <div className="overflow-x-auto frame">
                          <div className="w-full">
                            <Chart
                              chartType="ComboChart"
                              width="100%"
                              height="360px"
                              data={chartData}
                              options={chartOptions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">{/*spacer*/}</div>
                  </div>
                ) : (
                  <div className="grid grid-cols-6 lg:grid-cols-5">
                    <div className="hidden lg:block lg:col-span-1">
                      {/*spacer*/}
                    </div>
                    <div className="col-span-6 lg:col-span-3">
                      <div className="col-span-6 lg:col-span-3">
                        <div className="w-full flex justify-center p-2">
                          {["summary", "today", "month", "quarter"].includes(report.filter) ? (
                            <TableDAtaView
                              tableData={milkingViewData}
                              idUsage={tableSettings.idUsage}
                              useSerial={tableSettings.useSerial}
                              mapHash={{
                                "#": "#",
                                am_volume: t("table_formatter.table_am"),
                                pm_volume: t("table_formatter.table_pm"),
                                noon_volume: t("table_formatter.table_noon"),
                                date: t("table_formatter.table_date"),
                                total: t("milking_page.total"),
                                session_type: t("table_formatter.table_session"),
                                cattle_id: t("milking_page.min"),
                                cattle_name: t("cattle_page.name"),
                                volume: t("table_formatter.table_volume"),
                                min_vol: t("milking_page.min"),
                                max_vol: t("milking_page.max"),
                                avg_vol: t("milking_page.average"),
                                mon: t("table_formatter.table_month"),
                                week1: t("table_formatter.table_1st_wk"),
                                week2: t("table_formatter.table_2nd_wk"),
                                week3: t("table_formatter.table_3rd_wk"),
                                week4: t("table_formatter.table_4th_wk"),
                                // total: t("table_formatter.table_total"),
                              }}
                            />
                          ) : report.filter === "twoday" ? (
                            <EditDelTableDataView
                              tableData={milkingViewData}
                              idUsage={0}
                              useSerial={tableSettings.useSerial}
                              mapHash={{
                                "#": "#",
                                am_volume: t("table_formatter.table_am"),
                                pm_volume: t("table_formatter.table_pm"),
                                noon_volume: t("table_formatter.table_noon"),
                                date: t("table_formatter.table_date"),
                                total: t("milking_page.total"),
                                session_type: t("table_formatter.table_session"),
                                cattle_id: t("table_formatter.table_tag"),
                                cattle_name: t("cattle_page.name"),
                                volume: t("table_formatter.table_volume"),
                                min_vol: t("milking_page.min"),
                                max_vol: t("milking_page.max"),
                                avg_vol: t("milking_page.average"),
                                // needs to remove this
                                mon: t("table_formatter.table_month"),
                                week1: t("table_formatter.table_1st_wk"),
                                week2: t("table_formatter.table_2nd_wk"),
                                week3: t("table_formatter.table_3rd_wk"),
                                week4: t("table_formatter.table_4th_wk"),
                                // total: t("table_formatter.table_total"),
                              }}
                              selectedId={selectedId}
                              onSelectId={handleOnSelectId}
                            />
                          ) : null /* Optionally handle any other specific cases here */ }
                        </div>
                      </div>
                    </div>
                    <div className="hidden lg:block lg:col-span-1">
                      {/*spacer*/}
                    </div>
                  </div>
                )
              ) : createNotice.status === 404 ? (
                <div className="grid grid-cols-6 lg:grid-cols-5">
                  <div className="hidden lg:block lg:col-span-1">
                    {/*spacer*/}
                  </div>
                  <div className="col-span-6 lg:col-span-3">
                    <div className="w-full flex justify-center py-2">
                      <span>
                        {t("user_handle_notice.no_data_matching_criteria")}
                      </span>
                    </div>
                  </div>
                  <div className="hidden lg:block lg:col-span-1">
                    {/*spacer*/}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Footer
              respNotice={createNotice}
              setRespNotice={setCreateNotice}
              backLink="/fe/reports"
              footerType={footerType}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
            {/*</div>*/}
          </form>
        </div>
  
        <div>
          {/* Toast Container */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            pauseOnFocusLoss
            theme="light"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-[80px] py-4 md:px-8">
        <MilkingForm
          milking={milkingRecord}
          formMode={"edit"}
          onCancel={handleEditCancel}
          onEdit={handleEditUpdate}
        />
      </div>
    );
  }
  





}

export { MilkingsReport };
