import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    Footer,
    EditDelTableDataView,
  } from "../../components";
  import "./reportStyle.css";

import { useHandleSubmit } from "../../hooks";
import { useEnv } from "../../contexts";
import {
  getResource,
  guestCreateResource,
} from "../../services/farmApi";

import { useTranslation } from "react-i18next";

function UsersReport() {

    // for language translation
    const { t } = useTranslation();

    const showToast = (message, type) => {
        toast[type](message, {
          position: "top-center", 
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    };

    const [userViewData, setUserViewData] = useState([]);

    // Influences how table is displayed
    const [tableSettings, setTableSettings] = useState({
        idUsage: 1,
        useSerial: true,
    });
    const initNotice = useRef({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,
        ver: null,
    });
    const [createNotice, setCreateNotice] = useState(initNotice.current);

    // ID for the currently selected record in Edit and Delete table
    const [selectedId, setSelectedId] = useState(0);
    
    // Foot Type; nav -> default, editDelete -> to show edit and delete buttons
    const [footerType, setFooterType] = useState("nav");

    const { handleGetResource } = useHandleSubmit();
    const { envState, setEnvState } = useEnv(); // Get Environment context

    // Handle Radio's input record selection on Edit and Delete table
    const handleOnSelectId = (inputKey, inputValue) => {
        setSelectedId(inputValue);
    };

    /**
     * Notifiy the App of this location, provide Header Nav links
     */
    useEffect(() => {
        setEnvState((state) => ({
        ...state,
        whereAbout:  t("manage_users_page.manager_users"),
        backPath: "/fe/manage",             // : this returns back to home start, I think is ok as we come from the hamburger menu   
        homePath: "/fe",
        }));

        return () => {
        setEnvState((state) => ({
            ...state,
            whereAbout: "",
            backPath: "",
            homePath: "",
        }));
        };
        // eslint-disable-next-line
    }, [footerType]);


    // Fetch report
    useEffect((idUsage = 1, useSerial = true) => {

        setTableSettings({ idUsage, useSerial });
        // Fetch data and update reportFarmData
        // This is just an example, replace it with your logic
        getUserReport(); //  change to this to correct API URL
    }, []);

    const getUserReport = async () => {
            try {
                handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_AUTH_MANAGE_USERS,
                setCreateNotice,
                null,
                setUserViewData,
                null,
                false // explicitly expecting a single model?
            );
            } catch (error) {
                console.error('Error fetching farm data:', error);
            }
    };


    // handle hide/show edit and delete controls
    // : in my case, it will always be editdelete
    useLayoutEffect(() => {
        setFooterType("activeDeactive");
    }, [createNotice.succ]);

    // Added a confirmation dialog or modal before proceeding with the delete operation
    const handleActivate = async (e) => {
        e.preventDefault();
        // : to activate user, only admin are allowed to do this
        //       will set active=1, deleted_at to null 
        //       I also want to give admin access to decided on whether change to spr or ops , default ops

        if (selectedId) {

            const regResp = await guestCreateResource(
                process.env.REACT_APP_AUTH_ACTIVATE_USER,
                {
                    profile_type: 'ACTIVATE_USER_PROFILE',
                    selected_id: selectedId
                },
                t
            );

            if (regResp.success) {
                // Show Success Notificaton screen
                showToast(t('validation_messages.user_activation_successful'), 'info'); 

                // Delay the page reload for 2 seconds (adjust the time as needed)
                setTimeout(() => {
                    // Page refresh after the delay
                    window.location.reload();
                }, 5000); // 2000 milliseconds = 2 seconds
    
                return;
            }else{
                // Show Error Notificaton screen
                showToast(t('validation_messages.user_activation_failed'), 'error'); 
                return;           
            }

        }else {
            // inform user that nothing has been selected
              // info, error, success are possible options
              showToast(t("validation_messages.select_record_first"), "error");  
          }
    };

    // Fetch a record to edit
    const handleDeactivate = async (e) => {
        e.preventDefault();
        if (selectedId) {

            const regResp = await guestCreateResource(
                process.env.REACT_APP_AUTH_DEACTIVATE_USER,
                {
                    profile_type: 'DEACTIVATE_USER_PROFILE',
                    selected_id: selectedId
                },
                t
            );

            if (regResp.success) {
                // Show Success Notificaton screen
                showToast(t('validation_messages.user_deactivation_successful'), 'info'); 

                // Delay the page reload for 2 seconds (adjust the time as needed)
                setTimeout(() => {
                    // Page refresh after the delay
                    window.location.reload();
                }, 5000); // 2000 milliseconds = 2 seconds
    
                return;
            }else{
                // Show Error Notificaton screen
                showToast(t('validation_messages.user_deactivation_failed'), 'error'); 
                return;           
            }
        }else {
            // inform user that nothing has been selected
              // info, error, success are possible options
              showToast(t("validation_messages.select_record_first"), "error");  
          }
    };


    const formattedData = userViewData.map(user => {

        let role;
        if (user.role === 'spr') {
            role = 'Admin';
        } else if (user.role === 'ops') {
            role = 'Operator';
        } else {
            role = user.role; // Use the original role if it's neither 'spr' nor 'ops'
        }

        return {
            id: user.id,
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            role: role,
            email: user.email,
            active: user.active === 1 ? t('manage_users_page.active_for_status') : t('manage_users_page.inactive_for_status'),      
        };
    });

    return (
            <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
                <div className="col-span-2 flex flex-col justify-center items-center">
                    <form autoComplete="off" className="w-full">
                        <div className="py-5">
                            <div className="grid grid-cols-6 lg:grid-cols-5">
                                <div className="hidden lg:block lg:col-span-1">
                                {/*spacer*/}
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="w-full flex justify-center p-2">
                                        <EditDelTableDataView
                                            tableData={formattedData}
                                            idUsage={0}
                                            useSerial={tableSettings.useSerial}
                                            mapHash={{
                                                "#": "#",
                                                first_name: t('manage_users_page.first_for_name'),
                                                middle_name: t('manage_users_page.middle_for_name'),
                                                last_name: t('manage_users_page.last_for_name'),
                                                role: t('manage_users_page.role'),
                                                email: t('manage_users_page.email_for_table_format'),
                                                active: t('manage_users_page.status_for_state'),
                                            }}
                                            selectedId={selectedId}
                                            onSelectId={handleOnSelectId}
                                            // onSelectId={(inputKey, inputValue) => handleOnSelectId(inputKey, inputValue, formattedData[selectedRow].active)}
                                            // rowComponent={TableRowComponent} // adding ability when anywhere on row is selected should select radio button
                                        />
                                    </div>
                                </div>
                                <div className="hidden lg:block lg:col-span-1">
                                {/*spacer*/}
                                </div>
                            </div>
                        </div>
                        <Footer
                        respNotice={createNotice}
                        setRespNotice={setCreateNotice}
                        backLink="/fe/reports"
                        footerType={footerType}
                        onDeactivate={handleDeactivate}
                        onActivate={handleActivate}
                        />
                    </form>
                </div>
                <div>
                {/* Toast Container */}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    draggable
                    pauseOnFocusLoss
                    theme="light"
                />
                </div>
            </div>
      );


}

export {UsersReport};