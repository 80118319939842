// // Import the functions you need from the Firebase SDK
// // Updated Firebase Configuration for Messaging
// // Firebase now uses modular imports (v9+ SDK) for better tree-shaking and smaller bundle sizes. 
// import { initializeApp } from "firebase/app";
// import { onMessage, getMessaging, getToken as getFCMToken } from "firebase/messaging";
// import { ConsoleLogEvent } from './utils';

// // Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCJMTI6Spra0DaSgitaEC-WyXF0cpGwXLI",
//   authDomain: "gdf-app-3b212.firebaseapp.com",
//   projectId: "gdf-app-3b212",
//   storageBucket: "gdf-app-3b212.firebasestorage.app",
//   messagingSenderId: "12034228704",
//   appId: "1:12034228704:web:7821dabe1dc5f81899eb85",
//   measurementId: "G-KG1WXQZRE6",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// // Initialize Firebase and set up onMessage listener
// const firebaseInit = (setNotification) => {
//   onMessage(messaging, (payload) => {

//     ConsoleLogEvent('info', 'Foreground message received with payload:', payload);

//     // Safely access notification data
//     const notificationTitle = payload.notification?.title || "No Title";
//     const notificationBody = payload.notification?.body || "No Body";
//     const notificationImage = payload.notification?.image || "";

//     setNotification({
//       title: notificationTitle,
//       body: notificationBody,
//       image: notificationImage,
//     });
//   });
// };


// // Export initialized Firebase Messaging and token fetch utility
// export { messaging, getFCMToken,  firebaseInit};




// Import the functions you need from the Firebase SDK
import { initializeApp } from "firebase/app";
import { onMessage, getMessaging, getToken as getFCMToken } from "firebase/messaging";
import { ConsoleLogEvent } from './utils';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJMTI6Spra0DaSgitaEC-WyXF0cpGwXLI",
  authDomain: "gdf-app-3b212.firebaseapp.com",
  projectId: "gdf-app-3b212",
  storageBucket: "gdf-app-3b212.firebasestorage.app",
  messagingSenderId: "12034228704",
  appId: "1:12034228704:web:7821dabe1dc5f81899eb85",
  measurementId: "G-KG1WXQZRE6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Utility to check if the device is iOS or a browser incompatible with FCM
const isUnsupportedMessagingEnvironment = () => {
  const userAgent = navigator.userAgent || window.opera;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) || 
                (userAgent.includes('Mac') && 'ontouchend' in document);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  // Add additional checks if needed for unsupported environments
  return isIOS || isSafari; // Adjust as necessary based on testing
};

// Conditionally initialize messaging
let messaging = null;

if (!isUnsupportedMessagingEnvironment()) {
  try {
    messaging = getMessaging(app);
  } catch (error) {
    ConsoleLogEvent('error', 'Failed to initialize Firebase Messaging:', error);
  }
} else {
  ConsoleLogEvent('warn', 'Messaging is not supported in this environment.');
}

// Initialize Firebase and set up onMessage listener
const firebaseInit = (setNotification) => {
  if (!messaging) {
    ConsoleLogEvent('warn', 'Firebase Messaging is not initialized.');
    return;
  }

  onMessage(messaging, (payload) => {
    ConsoleLogEvent('info', 'Foreground message received with payload:', payload);

    // Safely access notification data
    const notificationTitle = payload.notification?.title || "No Title";
    const notificationBody = payload.notification?.body || "No Body";
    const notificationImage = payload.notification?.image || "";

    setNotification({
      title: notificationTitle,
      body: notificationBody,
      image: notificationImage,
    });
  });
};

// Export initialized Firebase Messaging and token fetch utility
export { messaging, getFCMToken, firebaseInit };

