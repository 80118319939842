import { TextInput, InputLabel, NumberInput, TextareaInput, Footer } from '../components';
import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { useState, useLayoutEffect, useEffect } from 'react';
import { createResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';

export const DipForm = () => {

    // for language translation
    const { t } = useTranslation();

    const { user } = useUser();  // Get currently loggedIn user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler
    const today = new Date().toISOString().split('T')[0];  // Todays' date
// --------------------- start deciding how to handle queued record if any -------------------

const [queuedRecord, setFarmRecord] = useState(null);
const [initFormState, setInitFormState] = useState(null);

useEffect(() => {
    // Retrieve record from local storage using the special key if was a failed queue
    // const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
    const queuedFarmRecord = localStorage.getItem(`queuedFarmRecord_${user.id}`);
    
    if (queuedFarmRecord) {
        const parsedFarmRecord = JSON.parse(queuedFarmRecord);
        setFarmRecord(parsedFarmRecord);

        // Delete the special key and its corresponding data from local storage
        // localStorage.removeItem('queuedFarmRecord');
        localStorage.removeItem(`queuedFarmRecord_${user.id}`);
        
    }
}, []);


useEffect(() => {
    if (queuedRecord) {
        // If queueRecord exists, initialize initFormState with its data
        setInitFormState({ ...queuedRecord, user_id: user.id });

    } else {
        // If queueRecord is null, initialize initFormState with default values
        setInitFormState({
          date: today,
          liquid_vol_before_dip: 0.0,
          liquid_vol_after_dip: 0.0,
          water_vol_added: 0.0,
          chemical_vol_added: 0.0,
          cattle_dipped: 0.0,
          notes: '',
          user_id: user.id,
        });
    }
}, [queuedRecord]);

// ensuring initFormState is initialized before rendering a page
useEffect(() => {
      if (initFormState !== null) {
        setFormState(initFormState);
      }
}, [initFormState]);

const resetFormState = () => { 
  setFormState(initFormState);
  setFarmRecord(null);
}

const [formState, setFormState] = useState(initFormState);

// --------------------- end deciding how to handle queued record if any -------------------

    // const [formState, setFormState] = useState(initFormState);
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState('');

    const textInputStyle = useEnaDisableStyle();
    // Takes in array off validation errors and returns jsx.
    const showFieldErrors = useErrorParser();

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_dip'), backPath: "/fe/home", homePath: "/fe"}));
      return () => {
        setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []);

    const handleChange = (inputKey, inputValue) => {
        setFormState({...formState, [inputKey]: inputValue});
    }

    if (initFormState === null) {
      return <div>{t("error_message.loading")}</div>; // or any loading indicator
    }

    return (
      <div className="mt-[100px] mb-[100px]">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form
                onSubmit={(e) => {
                  handleSubmit(
                    e,
                    formState,
                    createResource,
                    envState.online,
                    process.env.REACT_APP_DIPS,
                    setResponseErrors,
                    setCreateNotice,
                    resetFormState,
                    publish
                  )
                }}
                autoComplete="off"
              >
                <div className="overflow-hidden sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                        {/* Dip Date - date */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="date"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('manage_dip_page.dip_date')}
                          />
                          <TextInput
                            focus={false}
                            inputType="date"
                            inputName="date"
                            inputId="date"
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.date}
                            handleChange={handleChange}
                          />
                          {showFieldErrors(responseErrors.date)}
                        </div>
                        {/* Dip Liquid Volume Before - number */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="liquid_vol_before_dip"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('manage_dip_page.liquid_volume_before')}
                          />
                          <NumberInput
                            focus={false}
                            inputName="liquid_vol_before_dip"
                            inputId="liquid_vol_before_dip"
                            hint={t('manage_dip_page.liquid_volume_before')}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.liquid_vol_before_dip}
                            handleChange={setFormState}
                          />
                          {showFieldErrors(responseErrors.liquid_vol_before_dip)}
                        </div>
                        {/* Dip Liquid Volume After - number */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="liquid_vol_after_dip"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('manage_dip_page.liquid_volume_after')}
                          />
                          <NumberInput
                            focus={false}
                            inputName="liquid_vol_after_dip"
                            inputId="liquid_vol_after_dip"
                            hint={t('manage_dip_page.liquid_volume_after')}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.liquid_vol_after_dip}
                            handleChange={setFormState}
                         />
                         {showFieldErrors(responseErrors.liquid_vol_after_dip)}
                        </div>
                        {/* Dip Water Volume Added - number */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="water_vol_added"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('manage_dip_page.water_volume_added')}
                          />
                          <NumberInput
                            focus={false}
                            inputName="water_vol_added"
                            inputId="water_vol_added"
                            hint={t('manage_dip_page.water_volume_added')}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.water_vol_added}
                            handleChange={setFormState}
                          />
                          {showFieldErrors(responseErrors.water_vol_added)}
                        </div>
                        {/* Dip Chemical Volume Added - number */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="chemical_vol_added"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('manage_dip_page.chemical_volume_added')}
                          />
                          <NumberInput
                            focus={false}
                            inputName="chemical_vol_added"
                            inputId="chemical_vol_added"
                            hint={t('manage_dip_page.chemical_volume_added')}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.chemical_vol_added}
                            handleChange={setFormState}
                         />
                         {showFieldErrors(responseErrors.chemical_vol_added)}
                        </div>
                        {/* Cattle Dipped - number */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="cattle_dipped"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('manage_dip_page.cattle_dipped')}
                          />
                          <NumberInput
                            focus={false}
                            inputName="cattle_dipped"
                            inputId="cattle_dipped"
                            hint={t('manage_dip_page.number_cattle_dipped')} 
                            min={1}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.cattle_dipped}
                            handleChange={setFormState}
                          />
                          {showFieldErrors(responseErrors.cattle_dipped)}
                        </div>
                        {/* Notes - textarea */}
                        <div className="col-span-6">
                          <InputLabel
                            inputId="notes"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('page_elements.notes')}
                          />
                          <TextareaInput
                            focus={false}
                            inputName="notes"
                            inputId="notes"
                            hint={t('manage_dip_page.enter_notes_about_dip')}  
                            isRequired={false}
                            inputRows={3}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.notes}
                            handleChange={handleChange}
                          />
                          {showFieldErrors(responseErrors.notes)}
                        </div>
                    </div>
                  </div>
                  <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />
                </div>
              </form>
            </div>
          </div>
      </div>
    );
}