import { ConsoleLogEvent, generateRandomPassword, isFedCMSupported, GoogleIdentityServices } from "../../utils";
import { LockSolidClosedIcon, ArrowLongRightIcon, ArrowLongDownIcon } from "../../icons";
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { useUser } from "../../contexts/userContext";
import { eye } from 'react-icons-kit/feather/eye'
import { useNavigate } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { useState, useEffect } from "react";
import { login } from "../../services/auth";
import logo from "../../icons/gdf192.png";
import { useA2HS } from '../a2hsContext';
import { Icon } from 'react-icons-kit';
import { jwtDecode } from "jwt-decode";
import { Suspense } from 'react';
import Cookies from "js-cookie";
import { loadFacebookSDK } from "../../utils";
import { GoogleSignUpInIcon, FacebookSignUpInIcon } from "../../icons";
import "../../FacebookButton.css"; 
import "../../GoogleButton.css";

// Landing Auth component - Login Dialog
const Landing = ({ onChangeDialog }) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [authError, setAuthError] = useState("");
  const { setUser } = useUser();
  const navigate = useNavigate();
  const { deferredPrompt, setDeferredPrompt, showInstallPrompt, setShowInstallPrompt } = useA2HS();

  const [showLogInEmailForm, setLogInShowEmailForm] = useState(false); 
  const [showEmailLogInpButton, setShowEmailLogInpButton] = useState(true);
  const [googleLoginData, setGoogleLoginData] = useState(null); 
  const [loginError, setLoginError] = useState(null);
  const googleApiKey = process.env.REACT_APP_GOOGLE_GDF_PWA_FedCM_CLIEND_ID;
  const facebookApiKey = process.env.REACT_APP_FACEBOOK_GDF_PWA_AUTH_CLIEND_ID;
  const [fbReady, setFbReady] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // --------------------- start language matter --------------------------
  // for language
  const { t, i18n } = useTranslation();

  // Languages for the dropdown 
  // Emoji flag symbols https://apps.timwhitlock.info/emoji/tables/iso3166
  const languages = [
      { name: " \uD83C\uDDEC\uD83C\uDDE7 English", code: "en" },
      { name: "\uD83C\uDDF9\uD83C\uDDFF Kiswahili", code: "sw" },
  ];

  // read a cookie from the storage and accepts the name of the cookie
  const currentLocale = Cookies.get("gdf_i18next") || "en";

  // define a language variable and pass in the current locale via the useState Hook.
  const [language, setLanguage] = useState(currentLocale);

  // handleChangeLocale handler to trigger language update.
  const handleChangeLocale = (e) => {
      const lang = e.target.value;
 
      // Check if the selected language is different from the current language
      if (lang !== language) {

        // Update the cookie
        Cookies.set('gdf_i18next', lang, { 
          expires: 365,      // expires in 365 days, adjust as needed
          sameSite: 'None',  // Set SameSite attribute to 'None'
          secure: true       // Set secure to true if your site is served over HTTPS
        });

        // Update the state
        setLanguage(lang);

        // Update the i18n language
        i18n.changeLanguage(lang);
      }
  };
  // --------------------- end language matter --------------------------

  const handleLoginWithEmail = async (e) => {
    e.preventDefault();

    if (email.length < 9 || password < 5) {
      //  
      // setAuthError("Email or Password is invalid");
      setAuthError(t('error_message.wrong_email_or_password'));
    } else {
      const loginResp = await login(email, password, 'password');
      if (loginResp.success) { 
        localStorage.setItem(
          process.env.REACT_APP_AUTH_STORAGE_KEY,
          JSON.stringify(loginResp.data)
        );
        setUser(loginResp.data);
        setAuthError("");
        navigate("/fe");
      } else { 
        setUser(null);
        localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
        if (loginResp.status === 401) {
          //setAuthError("Wrong Email or Password");
          setAuthError(t('error_message.wrong_email_or_password'));
        } else if (loginResp.status === 402) {
          setAuthError(t('error_message.farm_not_activated_for_login'));
          ConsoleLogEvent("error", "Farm account not full activated, check your email inbox and activate", loginResp);
        } else {
          //setAuthError("Login failed. Try again later.");
          setAuthError(t('error_message.login_failed_try_again'));
          // setAuthError(loginResp.status);
        }
      }
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // Activate Register dialog
  const handleRegister = (e) => {
    e.preventDefault();
    onChangeDialog("register");
  };

  // Activate Forgot password dialog
  const handleForgotPass = (e) => {
    e.preventDefault();
    onChangeDialog("forgot");
  };

  // Function to open the popup
  const openPrivacyPopup = () => {
      // URL of the terms and conditions page
      let termsURL = "https://www.gdf.co.tz"; // Default URL

      // Adjust the URL based on the language
      if (i18n.language === 'en') {
          // termsURL = "https://www.gdf.co.tz/terms/en.html"; // English terms and conditions page URL
          termsURL = "https://www.gdf.co.tz/privacy/en.html"; // English privacy policy page URL
      } else if (i18n.language === 'sw') {
          // termsURL = "https://www.gdf.co.tz/terms/sw.html"; // Swahili terms and conditions page URL
          termsURL = "https://www.gdf.co.tz/privacy/sw.html"; // Swahili privacy policy page URL
      }
      
      // Open a new popup window with the terms and conditions page
      window.open(termsURL, "_blank", "width=400,height=900");
  };

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  // Add to Home Screen using pop using Swal
  const handleAddToHomeScreen = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        // console.log('User accepted the A2HS prompt');
      } else {
        // console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
      setShowInstallPrompt(false);
    }
  };

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);

      // Show the button 5 seconds after the `beforeinstallprompt` event
      setTimeout(() => {
        setShowInstallPrompt(true);
      }, 5000);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, [setDeferredPrompt, setShowInstallPrompt]);

  const handleLogInWithEmailClick = (e) => {
      e.preventDefault();
      setLogInShowEmailForm(true);
    setShowEmailLogInpButton(false);
  };


  // ---------------- start the Google part ------------------------------

    const handleGoogleLogin = async (response) => {
      ConsoleLogEvent("info", "Google Login Raw Response:", response);
  
      if (response?.error) {
        ConsoleLogEvent("error", "Login Error:", response.error);
        setLoginError(response.error);
        setGoogleLoginData(null);
        return;
      }
  
      try {
        const decoded = jwtDecode(response.credential);
        ConsoleLogEvent("info", "Decoded JWT user information:", decoded);
  
        const { email, email_verified, given_name, family_name } = decoded;
  
        if (email_verified) {
          ConsoleLogEvent("info", "Email is verified.");
          const passwordDummy = generateRandomPassword();
  
          if (email.length < 9) {
            setAuthError(t("error_message.wrong_email_or_password"));
            return;
          }
  
          // Perform login with the backend
          const loginResp = await login(email, passwordDummy, "OTT");
          if (loginResp?.success) {
            localStorage.setItem(
              process.env.REACT_APP_AUTH_STORAGE_KEY,
              JSON.stringify(loginResp.data)
            );
            setUser(loginResp.data);
            setAuthError("");
            navigate("/fe");
          } else {
            handleAuthErrors(loginResp);
          }
        }
  
        setGoogleLoginData({
          ...decoded,
          accessToken: response.credential,
          provider: "google",
        });
        setLoginError(null);
      } catch (err) {
        ConsoleLogEvent("error", "Failed to decode JWT:", err);
        setLoginError("Failed to decode token.");
      }
    };
  
    const handleAuthErrors = (loginResp) => {
      setUser(null);
      localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
  
      if (loginResp.status === 401) {
        setAuthError(t("error_message.wrong_email_or_password"));
        ConsoleLogEvent("error", "Unauthorized login (401):", loginResp);
      } else if (loginResp.status === 402) {
        setAuthError(t("error_message.farm_not_activated_for_login"));
        ConsoleLogEvent("error", "Farm not activated (402):", loginResp);
      } else {
        setAuthError(t("error_message.login_failed_try_again"));
        ConsoleLogEvent("error", "Login failed:", loginResp);
      }
    };
  
    const initializeGoogleLogin = () => {
      if (!window.google?.accounts?.id) {
        ConsoleLogEvent("error", "Google Identity Services is not available.");
        return;
      }
  
      window.google.accounts.id.initialize({
        client_id: googleApiKey,
        callback: handleGoogleLogin,
        use_fedcm_for_prompt: true,
      });
    };
  
    const handleGoogleSignOut = async () => {
      try {
        if (googleLoginData?.email && window.google?.accounts?.id) {
          window.google.accounts.id.revoke(googleLoginData.email, () =>
            ConsoleLogEvent("info", "Google consent revoked successfully.")
          );
        }
        setGoogleLoginData(null);
        setLoginError(null);
      } catch (error) {
        ConsoleLogEvent("error", "Failed to sign out:", error);
      }
    };
  
    useEffect(() => {
      GoogleIdentityServices()
        .then(() => {
          initializeGoogleLogin();
          ConsoleLogEvent("info", "Google Identity Services initialized successfully.");
        })
        .catch((error) => {
          ConsoleLogEvent("error", "Failed to load Google Identity Services:", error);
        });
    }, [googleApiKey]);
  
    useEffect(() => {
      const logoutOnDataChange = async () => {
        if (googleLoginData) {
          try {
            await handleGoogleSignOut();
            ConsoleLogEvent("info", "User signed out successfully after login.");
          } catch (error) {
            ConsoleLogEvent("error", "Error during logout:", error);
          }
        }
      };
  
      logoutOnDataChange();
    }, [googleLoginData]);


  // ---------------- end the Google part ------------------------------





  // ****************** start the Facebook Part ******************

  // useEffect(() => {
  //   // Load the Facebook SDK when the component mounts
  //   loadFacebookSDK(facebookApiKey)
  //     .then(() => {
  //       ConsoleLogEvent('info', 'Facebook SDK is ready');
  //       setFbReady(true);

  //       // Check if the user is already logged in
  //       window.FB.getLoginStatus((response) => {
  //         if (response.status === 'connected') {
  //           ConsoleLogEvent('info', 'User already logged in:', response);
  //           fetchFacebookUserData(response.authResponse);
  //         } else {
  //           ConsoleLogEvent('warn', 'User not logged in or session expired:', response);
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       ConsoleLogEvent('error', 'Error loading Facebook SDK:', error);
  //     });
  // }, []);

  useEffect(() => {
    // Load the Facebook SDK when the component mounts
    loadFacebookSDK(facebookApiKey)
      .then(() => {
        ConsoleLogEvent('info', 'Facebook SDK is ready');
        setFbReady(true);
  
        // Check if the user is already logged in
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            ConsoleLogEvent('info', 'User already logged into Facebook:', response);
  
            // Log out the user from Facebook
            handleFacebookLogout();

          } else {
            ConsoleLogEvent('warn', 'User not logged in or session expired:', response);
          }
        });
      })
      .catch((error) => {
        ConsoleLogEvent('error', 'Error loading Facebook SDK:', error);
      });
  }, []);
  



  const fetchFacebookUserData = (authResponse) => {
    const { accessToken, userID } = authResponse;
    window.FB.api('/me', { fields: 'email,first_name,last_name' }, (userData) => {
      ConsoleLogEvent('info', 'Fetched Facebook User Data:', userData);
      setUserData(userData);
      // Send userData.email, userData.first_name, and userData.last_name to your backend
      // handleFacebookLogin();
    });
  };

  const handleFacebookSignIn = () => {
    if (!window.FB) {
      ConsoleLogEvent('error', 'Facebook SDK is not loaded');
      return;
    }

    window.FB.login((response) => {
      if (response.status === 'connected') {
        ConsoleLogEvent('info', 'User successfully logged in:', response);
        fetchFacebookUserData(response.authResponse);
      } else {
        ConsoleLogEvent('warn', 'User cancelled login or did not fully authorize:', response);
      }
    }, { scope: 'public_profile,email' });
  };

  // This effect will run once userData is updated
  useEffect(() => {
    if (userData) {
      handleFacebookLogin(); // Call handleFacebookLogin once userData is set
    }
  }, [userData]); // Triggered whenever userData changes

  const handleFacebookLogin =  async () => {
    ConsoleLogEvent("info", "Facebook Login has now started with data:", userData);

    if (!userData) {
      ConsoleLogEvent("error", "Login Error as user data not available:");

      setLoginError(t('validation_messages.facebook_login_failed_try_email')); 
      
      setUserData(null);
      return;
    }

    try {
        const passwordDummy = generateRandomPassword();
        // Perform login with the backend
        const loginResp = await login(userData.email, passwordDummy, "OTT");
        if (loginResp?.success) {
          localStorage.setItem(
            process.env.REACT_APP_AUTH_STORAGE_KEY,
            JSON.stringify(loginResp.data)
          );
          setUser(loginResp.data);
          setAuthError("");
          navigate("/fe");

          // Once login is successful, logout from Facebook
          handleFacebookLogout(); // Call logout after login is successful

        } else {
          handleAuthErrors(loginResp);
        }
      setLoginError(null);
    } catch (err) {
      ConsoleLogEvent("error", "Failed to login user with facebook:", err);
      setLoginError(t('validation_messages.facebook_login_try_again_later')); 
      
    }
  };

  const handleFacebookLogout = () => {
    // log out user from facebook on this app as is not needed anymore
    if (!window.FB) {
      ConsoleLogEvent('error', 'Facebook SDK is not loaded');
      return;
    }
  
    window.FB.logout((response) => {
      if (response.status !== 'connected') {
        ConsoleLogEvent('info', 'User successfully logged out:', response);
        // Clear user-related data from your app
        setUserData(null);
        setIsLoggedOut(true); // Update state after logout
      } else {
        ConsoleLogEvent('warn', 'User is still connected:', response);
      }
    });
  };


  // ****************** end the Facebook Part ******************


  return (
    <>
      {/* Language  */}
      <div className="w-full flex justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="flex justify-end pt-2">
            {/** start using dropdown */}
            <div>
                {/* Language switch dropdown here */}
                <label htmlFor="lang">
                  { /** Select Language */}
                </label>
                <select 
                  className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
                  id="lang"
                  name="lang"
                  onChange={handleChangeLocale} 
                  value={language}
                >
                  {languages.map(({ name, code }) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
            </div>
            {/** end using dropdown */}
          </div>
        </div>
      </div>

      <div className="flex min-h-full items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* Header */}
          <div>
            {/* Logo */}
            <div className="flex justify-center">
              <img
                src={logo}
                width="80"
                height="80"
                className="w-[80px] h-auto"
                alt="GDF 512x512 sized logo"
              />
            </div>
            {/* Notification messages - if any */}
            <div className="text-gray-600  text-s p-0">
              {t('login_register_page.login_already_have_account')}
            </div> 
            <h2 className="flex min-h-full items-center justify-center text-gray-400 text-s p-0">
              {authError || loginError ? (
                <span className="text-red-400 text-sm">
                  {authError || loginError}
                </span>
              ) : (
                ""
              )}
            </h2>
          </div>
          <div className="flex flex-col gap-4">
            {showEmailLogInpButton && (
                <button 
                  onClick={handleLogInWithEmailClick} 
                  className="group relative flex w-full justify-center rounded-md bg-slate-500 py-2 px-4 text-sm text-white hover:bg-slate-600"
                >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <ArrowLongDownIcon
                        className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                        aria-hidden="true"
                      />
                    </span>
                    {t('buttons_navigation.button_sign_in_with_email')}
                </button>
              )}

            {/* Form */}
            {showLogInEmailForm && (
              <form className="mt-0" action="#" method="POST">
                <input type="hidden" name="remember" defaultValue="true" />
                  <div className="flex flex-col space-y-4 rounded-md shadow-sm text-lg">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        {t('forgot_password_page.email_address')}
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        onChange={handleEmail}
                        autoComplete="email"
                        required
                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                        placeholder={t('login_register_page.enter_email_address')}
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        {t('manage_profile_page.password_update')}
                      </label>    
                      <div className="mb-4 flex">
                          <input
                              id="password"
                              type={type}
                              name="password"
                              placeholder={t('login_register_page.enter_password')}
                              value={password}
                              onChange={handlePassword}
                              autoComplete="current-password"
                              required
                              className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                          />
                          <span className="flex justify-around items-center" onClick={handleToggle}>
                                <Icon className="absolute mr-10" icon={icon} size={25}/>
                          </span>
                      </div>
                    </div>
                  </div>
                  {/* Forgot password */}
                  <div className="text-right pt-3 pr-[2px]">
                    <span
                      onClick={handleForgotPass}
                      className="text-blue-600 font-bold block cursor-pointer"
                    >
                      {/*Forgot Password?*/}
                      {t('login_register_page.forgot_password')}

                    </span>
                  </div>
                  {/* Login Button */}
                  <div className="pt-2">
                    <button
                      type="submit"
                      // onClick={handleLoginWithEmail}
                      onClick={(e) => handleLoginWithEmail(e)}
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockSolidClosedIcon
                          className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                          aria-hidden="true"
                        />
                      </span>
                      {/*Login*/}
                      {t('buttons_navigation.button_login')}
                    </button>
                  </div>
              </form>
            )}

            {/* <div className="flex justify-center text-gray-600  text-s p-0">{t('login_register_page.login_or_mark')}</div> */}
            
            {/* Google Sign-In Button */}
            <button
              onClick={() => {
                if (isFedCMSupported()) {
                  window.google.accounts.id.prompt();
                } else {
                  // see what you can do here, great to make this meaningful
                  ConsoleLogEvent('warn', 'Your browser does not support FedCM. Please update to the latest version.');
                }
              }}
                className="gsi-material-button"
              >
              <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                  {/* SVG for Google logo */}
                  <GoogleSignUpInIcon />
                  {/* <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ display: "block" }}
                  > */}
                    {/* Google logo paths */}
                    {/* <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg> */}
                </div>
                <span className="gsi-material-button-contents">
                  {/* Use translation for the button text */}
                  {t('buttons_navigation.button_sign_in_with_google')}
                </span>
              </div>
    
            </button>

            {/* <div className="flex justify-center text-gray-600  text-s p-0">{t('login_register_page.login_or_mark')}</div> */}

            {/* Faceeok Sign-In Button */}          
            {fbReady ? (
              <button onClick={handleFacebookSignIn} className="fbi-material-button">
                  <div className="fbi-material-button-state"></div>
                    <div className="fbi-material-button-content-wrapper">
                      <div className="fbi-material-button-icon">
                        <FacebookSignUpInIcon />
                      </div>
                        <span className="fbi-material-button-contents">
                          {t('buttons_navigation.button_sign_up_with_facebook')}
                        </span>
                        <span style={{ display: "none" }}>
                          {t('buttons_navigation.button_sign_up_with_facebook')}
                        </span>
                    </div>
                  
              </button>
              ) : (
                <p>{t('login_register_page.facebook_sdk_loading')}</p>
              )}
          </div>









          

          <div className="space-y-2">
            <div className="p-2">
              {/*If you manage a diary farm and don't have a farm account, start
              your farm account by clicking the button below*/}
              {t('login_register_page.register_header_note')}
            </div>
            {/* Register Button */}
            <div className="pt-0">
              <button
                type="button"
                onClick={handleRegister}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <ArrowLongRightIcon
                    className="h-5 w-5 text-white group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                {/*Start a farm account*/}
                {t('buttons_navigation.button_nav_register_farm')}
              </button>
            </div>
          </div>

          {showInstallPrompt && deferredPrompt && (
            <div className="pt-0">
                <div className="text-gray-600  text-s p-0">
                {t('user_handle_notice.a2hs_notice')}
                </div>
                <button
                    type="button"
                    onClick={handleAddToHomeScreen}
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <ArrowLongRightIcon
                            className="h-5 w-5 text-white group-hover:text-indigo-400"
                            aria-hidden="true"
                        />
                    </span>
                    {t('user_handle_notice.a2hs_add_home_screen_button')}
                </button>
            </div>
          )}

          <div className="text-gray-600  text-xs">
            {t('terms_and_conditions.login_page_footer')}{' '}
            <span
              onClick={openPrivacyPopup}
              className="text-blue-600 font-bold cursor-pointer text-xs"
            >
              {t('terms_and_conditions.privacy_policy')}
            </span>
          </div>
          
        </div>
      </div>
    </>
  );
};


export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <Landing />
    </Suspense>
  )
}

export { Landing };
