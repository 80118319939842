import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Cookies from "js-cookie";

import {
  MarketplaceNavIcon,
  DataCaptureIcon,
  ReportNavIcon,
  ManageNavIcon,
  MoreNavIcon,
} from "../icons";

const BottomNavigation = () => {
  const { t } = useTranslation();

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(""); // State to track the active button
  const location = useLocation();
  
  const closeLanguageModal = () => setIsLanguageModalOpen(false);
  const closeThemeModal = () => setIsThemeModalOpen(false);

  // Handle button click
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName); // Update the active button state
  };
  
  const handleComingSoon = (buttonName) => {
    showToast(t('navigation_element.nav_coming_soon'), 'info')
  }

  const showToast = (message, type) => {
      toast[type](message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  };

  return (
    <>
    <div className="fixed bottom-0 left-0 w-full items-center bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700">
      <div className="flex justify-between items-center py-2 px-4">
          {/* Home Button */}
          <Link
              to="/fe/home"
              onClick={() => handleButtonClick("home")}
              onTouchStart={() => handleButtonClick("home")}
              className={`flex flex-col items-center ${
                activeButton === "home" || location.pathname === "/fe/home"
                  ? "text-blue-200"
                  : "text-gray-600 dark:text-gray-300"
              } text-gray-600 dark:text-blue-200`}
            >
              <DataCaptureIcon
                className={`h-6 w-6 ${
                  activeButton === "home" || location.pathname === "/fe/home"
                    ? "text-blue-200"
                    : ""
                }`}
              />
              <span className="text-xs">{t('buttons_navigation.button_bottom_nav_capture')}</span>
          </Link>

          {/* Reports Button */}
          <Link
              to="/fe/reports"
              onClick={() => handleButtonClick("reports")}
              onTouchStart={() => handleButtonClick("reports")}
              className={`flex flex-col items-center ${
                activeButton === "reports" || location.pathname === "/fe/reports"
                  ? "text-blue-200"
                  : "text-gray-600 dark:text-gray-300"
              } text-gray-600 dark:text-blue-200`}
            >
              <ReportNavIcon
                className={`h-6 w-6 ${
                  activeButton === "reports" || location.pathname === "/fe/reports"
                    ? "text-blue-200"
                    : ""
                }`}
              />
              <span className="text-xs">{t('buttons_navigation.button_bottom_nav_reports')}</span>
          </Link>

          {/* Manage Button */}
          <Link
              to="/fe/manage"
              onClick={() => handleButtonClick("manage")}
              onTouchStart={() => handleButtonClick("manage")}
              className={`flex flex-col items-center ${
                activeButton === "manage" || location.pathname === "/fe/manage"
                  ? "text-blue-200"
                  : "text-gray-600 dark:text-gray-300"
              } text-gray-600 dark:text-blue-200`}
            >
              <ManageNavIcon
                className={`h-6 w-6 ${
                  activeButton === "manage" || location.pathname === "/fe/manage"
                    ? "text-blue-200"
                    : ""
                }`}
              />
              <span className="text-xs">{t('buttons_navigation.button_bottom_nav_manage')}</span>
          </Link>

          {/* TODO: comment this out if not on production for Marketplace Button */}
          {/* <Link
              to="/fe/marketplace"
              onClick={() => handleButtonClick("marketplace")}
              onTouchStart={() => handleButtonClick("marketplace")}
              className={`flex flex-col items-center ${
                activeButton === "marketplace" ||
                location.pathname === "/fe/marketplace"
                  ? "text-blue-200"
                  : "text-gray-600 dark:text-gray-300"
              } text-gray-600 dark:text-blue-200`}
            >
              <MarketplaceNavIcon
                className={`h-6 w-6 ${
                  activeButton === "marketplace" ||
                  location.pathname === "/fe/marketplace"
                    ? "text-blue-200"
                    : ""
                }`}
              />
              <span className="text-xs">{t('buttons_navigation.button_bottom_nav_marketplace')}</span>
          </Link> */}

          {/** TODO: comment this out when marketplace is not ready */}
          <Link
              to=""
              onClick={() => handleComingSoon("marketplace")}
              className={`flex flex-col items-center ${
                activeButton === "marketplace" ||
                location.pathname === ""
                  ? "text-cyan-3000"
                  : "text-gray-600 dark:text-gray-300"
              } text-gray-600 dark:text-blue-200`}
            >
              <MarketplaceNavIcon
                className={`h-6 w-6 ${
                  activeButton === "marketplace" ||
                  location.pathname === ""
                    ? "text-blue-200"
                    : ""
                }`}
              />
              <span className="text-xs">{t('buttons_navigation.button_bottom_nav_marketplace')}</span>
          </Link>

          {/* More Button */}
          <Link
              to="/fe/more"
              onClick={() => handleButtonClick("more")}
              onTouchStart={() => handleButtonClick("more")}
              className={`flex flex-col items-center ${
                activeButton === "more" || location.pathname === "/fe/more"
                  ? "text-blue-200"
                  : "text-gray-600 dark:text-gray-300"
              } text-gray-600 dark:text-blue-200`}
            >
              <MoreNavIcon
                className={`h-6 w-6 ${
                  activeButton === "more" || location.pathname === "/fe/more"
                    ? "text-blue-200"
                    : ""
                }`}
              />
              <span className="text-xs">{t('buttons_navigation.button_bottom_nav_more')}</span>
          </Link>

        </div>
      </div>
      <div>
          {/* Toast Container */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            pauseOnFocusLoss
            theme="light"
          />
      </div>

      {/* Language Modal */}
      {isLanguageModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm">
            <LanguageForm onClose={closeLanguageModal} />
          </div>
        </div>
      )}

      {/* Theme Modal */}
      {isThemeModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm">
            <ThemeForm onClose={closeThemeModal} />
          </div>
        </div>
      )}
    </>
  );
};

export { BottomNavigation };


// Theme Selection Modal
const ThemeForm = ({ onClose }) => {
  const [theme, setTheme] = useState(() => {
    // Check for previously saved theme in localStorage or default to "light"
    return localStorage.getItem("gdfTheme") || "light";
  });

  const handleThemeChange = (e) => {
    const selectedTheme = e.target.value;
    setTheme(selectedTheme);

    // Update theme in localStorage and apply it to the document
    localStorage.setItem("gdfTheme", selectedTheme);
    document.documentElement.classList.remove("light", "dark");
    document.documentElement.classList.add(selectedTheme);
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-xl font-semibold text-center mb-4">
        Change Theme
      </h2>
      <div className="flex justify-center">
        <select
          className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
          value={theme}
          onChange={handleThemeChange}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
      </div>
      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-slate-400"
          onClick={onClose} // Close the modal
        >
          Close
        </button>
      </div>
    </div>
  );
};
export { ThemeForm };

// Modal Component for Language Selection
const LanguageForm = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const languages = [
    { name: " \uD83C\uDDEC\uD83C\uDDE7 English", code: "en" },
    { name: "\uD83C\uDDF9\uD83C\uDDFF Kiswahili", code: "sw" },
  ];

  const currentLocale = Cookies.get("gdf_i18next") || "en";
  const [language, setLanguage] = useState(currentLocale);

  const handleChangeLocale = (e) => {
    const lang = e.target.value;
    if (lang !== language) {
      Cookies.set('gdf_i18next', lang, { expires: 365, sameSite: 'None', secure: true });
      setLanguage(lang);
      i18n.changeLanguage(lang);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm">
        <h2 className="text-xl font-semibold text-center mb-4">
          {t('hamburger_messages.change_language_information')}
        </h2>
        
        <div className="flex justify-center">
          <select
            className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
            id="lang"
            name="lang"
            onChange={handleChangeLocale}
            value={language}
          >
            {languages.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-slate-400 ocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
            onClick={onClose}
          >
            {t('buttons_navigation.button_agree_ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

