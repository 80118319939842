import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { NotificationProvider } from "./components/NotificationContext";
import { A2HSProvider } from './components/a2hsContext';
import reportWebVitals from './reportWebVitals';
import { ConsoleLogEvent } from './utils';
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import './index.css';
import './i18n'; 

// ----------- Detect iOS and Standalone Mode -----------

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) || 
         (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

// Function to detect if the app is running in standalone mode
const isInStandaloneMode = () =>
  ('standalone' in window.navigator) && window.navigator.standalone;

// ----------- Handle A2HS for iOS -----------
if (isIOS() && !isInStandaloneMode()) {
    // Show custom message to users
    const installMessageDiv = document.createElement('div');
    installMessageDiv.id = 'ios-install-message';
    installMessageDiv.style.position = 'fixed';
    installMessageDiv.style.bottom = '20px';
    installMessageDiv.style.left = '50%';
    installMessageDiv.style.transform = 'translateX(-50%)';
    installMessageDiv.style.backgroundColor = '#f9f9f9';
    installMessageDiv.style.border = '1px solid #ddd';
    installMessageDiv.style.borderRadius = '10px';
    installMessageDiv.style.padding = '15px';
    installMessageDiv.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
    installMessageDiv.style.zIndex = '1000';
    installMessageDiv.style.width = '90%';
    installMessageDiv.style.maxWidth = '400px';
    installMessageDiv.style.textAlign = 'center';
    installMessageDiv.style.display = 'flex';
    installMessageDiv.style.alignItems = 'center';
    installMessageDiv.style.justifyContent = 'center';
    installMessageDiv.style.gap = '10px';

    installMessageDiv.innerHTML = 
        `
              <!-- Add Icon -->
              <div style="width: 30px; height: 30px; background-color: #A2AAAD; border-radius: 8px; display: inline-flex; justify-content: center; align-items: center; flex-shrink: 0;">
                <svg width="28" height="28" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <line x1="20" y1="5" x2="20" y2="35" stroke="white" stroke-width="2" />
                  <line x1="5" y1="20" x2="35" y2="20" stroke="white" stroke-width="2" />
                </svg>
              </div>

              <!-- Text -->
                <p style="margin: 0;">
                  To install GDF Dairy App® on your phone, tap 
                  <strong style="display: inline-flex; align-items: center;">
                    <svg 
                      fill="#0E7AFE" 
                      width="30px" 
                      height="30px" 
                      viewBox="0 -5 50 40" 
                      xmlns="http://www.w3.org/2000/svg"
                      style="vertical-align: baseline; margin: 0 1px;">
                      <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z"/>
                      <path d="M24 7h2v21h-2z"/>
                      <path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z"/>
                    </svg>
                  </strong> 
                  and then <strong>Add to Home Screen</strong>.
                </p>

              <!-- Close Button -->
              <button id="close-install-message" 
                style="padding: 8px 12px; font-size: 14px; color: white; background-color: #007BFF; border: none; border-radius: 5px; cursor: pointer;">
                Close
              </button>
        `
    ;

    document.body.appendChild(installMessageDiv);

    // Close button functionality
    document.getElementById('close-install-message').addEventListener('click', () => {
      document.body.removeChild(installMessageDiv);
    });

}

// ----------- start of FCM part ------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <A2HSProvider>
      <NotificationProvider>
          <App />
      </NotificationProvider>
    </A2HSProvider>
  </React.StrictMode>
);

// ----------- end of FCM part ------------------------------------------

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (event) => {
  // Prevent the default mini-infobar from appearing
  event.preventDefault();

  // Store the event for triggering later
  deferredPrompt = event;

  // Example: Show a custom install button
  const installButton = document.getElementById('install-button');
  if (installButton) {
    installButton.style.display = 'block';

    installButton.addEventListener('click', async () => {
      // Trigger the prompt
      deferredPrompt.prompt();

      // Check the user's choice
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          ConsoleLogEvent('log', 'User accepted the install prompt');
        } else {
          ConsoleLogEvent('log', 'User dismissed the install prompt');
        }
        // Clear the deferred prompt
        deferredPrompt = null;
        
        // Optionally, remove the install button
        installButton.remove();
      });
    });
  }
});


const serviceWorkerConfig = {
  onUpdate: (registration) => {
    ConsoleLogEvent('log', 'A new service worker is available:', registration);
    // Optional: Prompt user to refresh the app
    alert('GDF Dairy App® new version is available. Please refresh the page or app.');

  },
  onSuccess: (registration) => {
    ConsoleLogEvent('log', 'Service worker registered successfully:', registration);
  },
};

// serviceWorkerRegistration.register();
serviceWorkerRegistration.register(serviceWorkerConfig)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
