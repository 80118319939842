import { TextInput, InputLabel, SelectInput, TextareaInput, Footer, NumberInput, BottomNavigation } from '../components';
import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { TRANS_TYPE_REQUEST_ITEMS } from '../constants';
import { toast, ToastContainer } from 'react-toastify';
import { createResource } from '../services/farmApi';
import React, { useState, useEffect, useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';

import { ConsoleLogEvent } from '../utils';

import Select from 'react-select'; 
import countryList from 'react-select-country-list';

const RequestBuyForm = () => {
    // for language translation
    const { t } = useTranslation();
    const {envState, setEnvState } = useEnv(); // Get Environment context

    const { user } = useUser();  // Get currently loggedIn user
    const today = new Date().toISOString().split('T')[0]; // Todays' date
    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler

    // const [fileBytesSize, setFileBytesSize] = useState(0);
    // const [fileName, setFileName] = useState('');
    // const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState(null); // Error message state

    // ------------------ start refactor -------------------------
    const [initFormState, setInitFormState] = useState(null);
    const resetFormState = () => { setFormState(initFormState) }

    // *********************** start country name selection *************************************
    const [countryValue, setCountryValue] = useState('');
    const allCountries = useMemo(() => countryList().getData(), []);
    const prioritizedCountries = [
        { label: 'Tanzania', value: 'TZ' },
        { label: 'Kenya', value: 'KE' },
        { label: 'Rwanda', value: 'RW' },
        { label: 'Uganda', value: 'UG' },
        { label: 'Burundi', value: 'BI' },
        { label: 'Zambia', value: 'ZM' },
        { label: 'Malawi', value: 'MW' },
    ];

    // Merge prioritized countries with the rest of the list
    const options = useMemo(() => {
        const filteredCountries = allCountries.filter(
        (country) =>
            !prioritizedCountries.some((prioritized) => prioritized.value === country.value)
        );
        return [...prioritizedCountries, ...filteredCountries];
    }, [allCountries]);

    const changeCountryHandler = (value) => {
        ConsoleLogEvent('info', 'country change handler label (country name): ', value.label);
        ConsoleLogEvent('info', 'country change handler value (country code): ', value.value);
        setCountryValue(value);

        setFormState((prevState) => ({
            ...prevState,
            countryName: value.label,
            countryCode: value.value,
        }));
    }

    // *********************** end country name selection ***************************************
 
    // Setting initial form state in useEffect
    useEffect(() => {
        const initialState = {
            trans_type: TRANS_TYPE_REQUEST_ITEMS, // B Requesting
            region: '',
            description: '',
            category: '',
            requester_id: user.email,
            status_change_note: 'An item requested for the first time',
            image_base: null,
            media_url: process.env.REACT_APP_MEDIA_UPLOAD,
            media_file_name: 'noimagegiven.avif',
            media_file_type: 'image/avif',
            media_bytes_size: 9892,
            media_type: 'image',
            file_format: 'avif',
            user_id: user.id, // assuming user is defined in the scope
            countryName: '',
            countryCode: '',
        };

        setInitFormState(initialState);
        setFormState(initialState); // Set formState when initFormState is set
    }, []);

    
    const [formState, setFormState] = useState({
        trans_type: TRANS_TYPE_REQUEST_ITEMS, // B Requesting
        region: '',
        description: '',
        category: '',
        requester_id: user.email,
        status_change_note: 'An item requested for the first time',
        image_base: null,
        media_url: process.env.REACT_APP_MEDIA_UPLOAD,
        media_file_name: 'noimagegiven.avif',
        media_file_type: 'image/avif',
        media_bytes_size: 9892,
        media_type: 'image',
        file_format: 'avif',
        user_id: user.id,  // Assuming `user.id` is defined
        countryName: '',
        countryCode: '',
    });

    const handleChange = (inputKey, inputValue) => {
        if (inputKey === 'image') {
            const selectedFile = inputValue; // Safely get the first file
    
            if (selectedFile) {
                // Set file details in the form state
                setFile(selectedFile);

                const mediaFileName = selectedFile.name;
                const mediaFileType = selectedFile.type;    // e.g., 'image/jpeg'
                const mediaFileBytesSize = selectedFile.size;

                // Split fileType into mediaType and fileFormat
                const [mediaType, fileFormat] = mediaFileType.split('/'); // 'image', 'jpeg'

                // Update the formState immediately with file details
                setFormState((prevState) => ({
                    ...prevState,
                    media_file_name: mediaFileName,       // Set file name in form state
                    media_file_type: mediaFileType,       // Set file type in form state
                    media_bytes_size: mediaFileBytesSize, // Set file size in form state
                    media_type: mediaType,           // Set media type (e.g., 'image')
                    file_format: fileFormat,         // Set file format (e.g., 'jpeg')
                }));
    
                // Convert the file to base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Image = reader.result; // Get base64 image/video
    
                    // Update formState with base64 image/video and set image selected status
                    setFormState((prevState) => ({
                        ...prevState,
                        image_base: base64Image,  // Store base64 string in formState
                        image: true,               // Indicate an image has been selected
                    }));
                };
                reader.readAsDataURL(selectedFile);  // Trigger base64 conversion

            } else {
                // No file selected; update the state accordingly
                setFormState((prevState) => ({
                    ...prevState,
                    image_base: '',  // Clear base64 string if no file selected
                    image: false,    // Indicate no image is selected
                }));
            }
        } else {
            // For non-file inputs, keep the current behavior
            setFormState((prevState) => ({
                ...prevState,
                [inputKey]: inputValue,
            }));
    
            // Clear the corresponding error message if the user is typing again
            setResponseErrors((prevErrors) => ({
                ...prevErrors,
                [inputKey]: [], // Clear the specific error when the user changes the input
            }));
        }
    };

    // This effect will log the formState whenever it changes
    useEffect(() => {
        // console.log("Current formState:", formState);
    }, [formState]); // This will log formState whenever it changes

    const submitRequestForm = async (e) =>  {
        e.preventDefault();

        // Clear previous errors before validation
        setResponseErrors({});
        
        // Validate region (should not be empty)
        if (formState.description === '') {
            setResponseErrors({ description: [t('market_place_page.provide_description')] });
            return;
        }

        if (formState.category === '') {
            setResponseErrors({ category: [t('market_place_page.specify_category')] });
            return;
        }

        // Validate region (should not be empty)
        if (formState.region === '') {
            setResponseErrors({ region: [t('market_place_page.select_region')] });
            return;
        }

        // Country select is import and needed for market filter
        if (formState.countryName === '' || formState.countryCode === ''){
            setResponseErrors({ countrySelect: [t('manage_profile_page.country_name_not_selected')] });
            return;
        }


        submitForm(e, formState);
 
    };

    // Handle media upload
    const handleMediaUpload = async () => {
        if (file) {
            // setStatus('uploading');
            setLoading(true);  // Start loading

            const formData = new FormData();
            formData.append('file', file);  

            try {
                // Upload to your server
                const result = await fetch(formState.media_url, {
                    method: 'POST',
                    body: formData,
                });

                const data = await result.json();
                // setStatus('success');
                setLoading(false); // Stop loading

                return true;  // Return success
            } catch (error) {
                // console.error(error);
                // setStatus('fail');
                setLoading(false);
                setErrorMessage(t('market_place_page.failed_upload'));
                return false;  // Return failure
            }
        }else {
            // Set status to initial if no file is present
            // setStatus('initial');
            return false; // No file to upload
        }
    };

    // // Submit form with necessary parameters
    // const submitForm = (e, formState) => {
    //     e.preventDefault(); // Prevent form from reloading the page

    //     // Submit form using handleSubmit, passing formState and necessary parameters
    //     handleSubmit(
    //         e,
    //         formState,  // pass formState with base64 and media details
    //         createResource,
    //         envState.online,
    //         process.env.REACT_APP_MARKET_PLACE,
    //         setResponseErrors,
    //         setCreateNotice,
    //         resetFormState,
    //         publish,
    //         t 
    //     );

    //     // Display success message and reset form
    //     toast.success(t('market_place_page.item_uploaded_users_notified'));
    // };


    // Submit form with necessary parameters
    const submitForm = async (e, formState) => {
        e.preventDefault(); // Prevent form from reloading the page

        try {
            // Call handleSubmit and await its response
            const response = handleSubmit(
                e,
                formState,  // pass formState with base64 and media details
                createResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE,
                setResponseErrors,
                setCreateNotice,
                resetFormState,
                publish,
                t 
            );

            toast.success(t('market_place_page.item_uploaded_users_notified'));

            // // Check the response status
            // if (response?.status === 503) {
            //     // Handle maintenance mode message
            //     toast.warn(t('market_place_page.marketplace_maintenance_message'));
            // } else if (response?.status === 200 || response?.status === 201) {
            //     // Display success message and reset form
            //     toast.success(t('market_place_page.item_uploaded_users_notified'));
            // } else {
            //     // Handle other errors
            //     console.info("Form submission notice:", {createNotice});
            //     console.info("Form submission notice:", responseErrors);
            //     toast.error(t('market_place_page.error_executing_request'));
            // }
        } catch (error) {
            // Handle unexpected errors
            // console.error("Form submission error:", error);
            toast.error(t('market_place_page.unexpected_error'));
        }
    };


    
    return (
        <div className="container">
            <div>{t('market_place_page.description_what_looking_for')}</div>
            <form onSubmit={submitRequestForm}>
                {/* Description input */}
                <div className="col-span-6 mt-2">
                {/* <div className="flex space-x-4 mb-4" role="group" aria-label="description"> */}
                    <InputLabel
                        inputId="description"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t('market_place_page.manage_face_item_side_item_description')}
                    />
                    <TextareaInput
                        focus={true}
                        inputName="description"
                        inputId="description"
                        hint={t('market_place_page.advertise_face_description_tip')}
                        isRequired={false}
                        inputRows={4}
                        inputStyle={textInputStyle.current.enabledInput}
                        inputValue={formState?.description}
                        maxLength={512}
                        handleChange={handleChange}
                    />
                    {showFieldErrors(responseErrors.description)}
                </div> 

                {/* Category - Select */}
                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                    <InputLabel
                        inputId="category"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t('market_place_page.advertise_face_select_category')}
                    />
                    <SelectInput
                        focus={false}
                        inputName="category"
                        inputId="category"
                        isRequired={false}
                        inputStyle={textInputStyle.current.enabledInput}
                        inputValue={formState?.category}
                        handleChange={handleChange}

                        choices={[
                            {key: '', label: t('market_place_page.advertise_face_select_category') },
                            {key: 'machine', label: t('market_place_page.request_face_category_machinery') },
                            {key: 'medicine', label:  t('market_place_page.request_face_category_cattle_medicine') },
                            {key: 'fodder', label:  t('market_place_page.request_face_category_cattle_feed') },
                            {key: 'veterinary', label:  t('market_place_page.request_face_category_veterinary') },
                            {key: 'cattle', label:  t('market_place_page.request_face_category_cattle') },
                            {key: 'seed', label:  t('market_place_page.request_face_category_AI_seed') },
                            {key: 'worker', label:  t('market_place_page.request_face_category_farm_worker') },
                            {key: 'other', label:  t('market_place_page.request_face_category_other') },
                        ]}
                    />
                    {showFieldErrors(responseErrors.category)}
                </div>

                {/* Region name Input */}
                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                    <InputLabel
                        inputId="region"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t('market_place_page.advertise_face_location')}
                    />
                    <TextInput
                        focus={false}
                        inputType="text"
                        inputName="region"
                        inputId="region"
                        hint={t('market_place_page.hint_enter_location')}
                        isRequired={false}
                        // patternInfo={t('cattle_page.cattle_tag_pattern_info')}
                        inputStyle={textInputStyle.current.enabledInput}
                        inputValue={formState?.region}
                        handleChange={handleChange} 
                    />
                    {showFieldErrors(responseErrors.region)}
                </div>


                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                    <InputLabel
                        inputId="countrySelect"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t('manage_profile_page.select_country_name')}
                    />
                    <Select 
                        options={options} 
                        value={countryValue} 
                        onChange={changeCountryHandler} 
                        placeholder={t('manage_profile_page.specify_country_name')} 
                        inputName="countrySelect"
                        inputId="countrySelect"
                    />
                    {showFieldErrors(responseErrors.countrySelect)}
                </div>



                {/* Image upload */}
                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                {/* <div className="flex space-x-4 mb-4" role="group" aria-label="image"> */}
                    <InputLabel
                        inputId="image"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t('market_place_page.advertise_face_picture_upload_title')}
                    />
                    <TextInput
                        focus={false}
                        inputType="file"
                        inputName="image"
                        inputId="image"
                        accept="image/*,video/*"
                        isRequired={false}
                        inputStyle={textInputStyle.current.enabledInput}
                        handleChange={handleChange} // Use handleChange for form state management
                    />
                    {showFieldErrors(responseErrors.image)}
                </div>

                {/* Indicate the media upload status */}
                {/* <div><Result status={status} /></div> */}
                {loading && <div>{t('validation_messages.loading_products')}.</div>}
                {errorMessage && <div className="text-red-500">{errorMessage}</div>}

                <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="submit"/>
            </form>
            <ToastContainer />
                       {/* Bottom Navigation */}
                       {/* <BottomNavigation /> */}
        </div>
    );
};

export { RequestBuyForm };
