import { BottomNavigation, MenuLowerCaseItem } from "../components";
import { useLayoutEffect, useState } from "react";
import { DummyMenuItem } from "./DummyMenuItem";
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from "../contexts";
import { MenuItem } from "./MenuItem";
import Cookies from "js-cookie";

import {
    PasswordRestIcon,
    TransactionsIcon,
    UpdateUsersIcon,
    CubeTransparent,
    ViewFinderIcon,
    LanguageIcon,
    StopIcon,
} from "../icons";

const ManageMenu = () => {
    const { user } = useUser();  // Get currently loggedIn user
    const { setEnvState } = useEnv(); // Get Environment context Setter
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_manage'), backPath: "", homePath: "/fe"}));
       return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: "/fe"}));
       }
       // eslint-disable-next-line
     }, []);

    // for language translation
    const { t } = useTranslation();

    const handleOpenLanguageModal = () => {
      // setIsMoreMenuOpen(false); // Close "More" menu
      setIsLanguageModalOpen(true); // Open Language Modal
    };

    const handleOpenThemeModal = () => {
      setIsThemeModalOpen(true); // Open Language Modal
    };
  
    const closeLanguageModal = () => setIsLanguageModalOpen(false);
    const closeThemeModal = () => setIsThemeModalOpen(false);
         
    return(
        // <div className="mt-[140px] mb-[100px] p-2 md:p-0 ">
        <div className="mt-[100px] mb-0 p-2 md:p-0">
            <div className="grid grid-cols-6">
                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div className="col-span-4 flex flex-col gap-y-4">
                    {/** Menu Item row */}

                    {/** Transactions and Manage Profile buttons */}
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/transactions" itemTitle={t('navigation_element.nav_item_title_transactions')}>
                                <TransactionsIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                         <MenuItem urlSegment="/fe/manageprofile" itemTitle={t('navigation_element.nav_item_title_manage_profile')}> 
                            <PasswordRestIcon />
                        </MenuItem>                       
                    </div>

                    {/** GenAI and Update Users buttons */}
                    <div className="flex justify-center items-center gap-x-3">
                        <MenuLowerCaseItem urlSegment="/fe/gdfai" itemTitle={t('buttons_navigation.button_ai_gdf_chat')}> 
                            <CubeTransparent />
                        </MenuLowerCaseItem>   


                        <MenuItem urlSegment="/fe/reports/users" itemTitle={t("hamburger_messages.update_users")}> 
                            <UpdateUsersIcon />
                        </MenuItem>
                    </div>

                    {/** Change Language and Theme (TBC) */}
                    <div className="flex justify-center items-center gap-x-3">
                       <button onClick={handleOpenLanguageModal}>
                            <MenuItem itemTitle={t("hamburger_messages.change_language")}>
                                <LanguageIcon />
                            </MenuItem>
                        </button>

                        <button onClick={handleOpenThemeModal}>
                            <MenuItem itemTitle={t("hamburger_messages.change_theme")}>
                                <ViewFinderIcon />
                            </MenuItem>
                        </button>
                        {/* <DummyMenuItem itemTitle={t('page_elements.tbc')}>
                                <StopIcon />
                        </DummyMenuItem> */}
                    </div>                    
                </div>
                {/** Left spacer */}
                <div className="col-span-1"></div>
                {/* Bottom Navigation */}
                <BottomNavigation />
            </div>
            <div className="flex flex-col justify-center items-center mt-2 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                {/* <span className="whitespace-nowrap">{t('home_page.courtesy')}</span> */}
                <span className="whitespace-nowrap">{t('home_page.web')}</span>
                <span className="whitespace-nowrap">{t('home_page.email')}</span>
                <span className="whitespace-nowrap">{t('home_page.copyright')}</span>
            </div>

            {/* Language Modal */}
            {isLanguageModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white rounded-lg p-6 w-full max-w-sm">
                <LanguageForm onClose={closeLanguageModal} />
                </div>
            </div>
            )}
    
            {/* Theme Modal */}
            {isThemeModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white rounded-lg p-6 w-full max-w-sm">
                <ThemeForm onClose={closeThemeModal} />
                </div>
            </div>
            )}

        </div>
    );
}

export { ManageMenu };


// Theme Selection Modal
const ThemeForm = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState(() => {
    // Check for previously saved theme in localStorage or default to "light"
    return localStorage.getItem("gdfTheme") || "light";
  });

  const handleThemeChange = (e) => {
    const selectedTheme = e.target.value;
    setTheme(selectedTheme);

    // Update theme in localStorage and apply it to the document
    localStorage.setItem("gdfTheme", selectedTheme);
    document.documentElement.classList.remove("light", "dark");
    document.documentElement.classList.add(selectedTheme);
  };

  return (
    <div className="modal flex flex-col items-center">
      <h2 className="text-xl font-semibold text-center mb-4">
        {t("hamburger_messages.change_theme")}
      </h2>
      <div className="flex justify-center">
        <select
          className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
          value={theme}
          onChange={handleThemeChange}
        >
          <option value="light">{t("hamburger_messages.light_theme")}</option>
          <option value="dark">{t("hamburger_messages.dark_theme")}</option>
        </select>
      </div>
      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-slate-400"
          onClick={onClose} // Close the modal
        >
          {t("hamburger_messages.close_theme")}
        </button>
      </div>
    </div>
  );
};
export { ThemeForm };

// Modal Component for Language Selection
const LanguageForm = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const languages = [
    { name: " \uD83C\uDDEC\uD83C\uDDE7 English", code: "en" },
    { name: "\uD83C\uDDF9\uD83C\uDDFF Kiswahili", code: "sw" },
  ];

  const currentLocale = Cookies.get("gdf_i18next") || "en";
  const [language, setLanguage] = useState(currentLocale);

  const handleChangeLocale = (e) => {
    const lang = e.target.value;
    if (lang !== language) {
      Cookies.set('gdf_i18next', lang, { expires: 365, sameSite: 'None', secure: true });
      setLanguage(lang);
      i18n.changeLanguage(lang);
    }
  };

  return (
    <div className="modal">
      <div className="rounded-lg p-6 w-full max-w-sm">
        <h2 className="text-xl font-semibold text-center mb-4">
          {t('hamburger_messages.change_language_information')}
        </h2>
        
        <div className="flex justify-center">
          <select
            className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
            id="lang"
            name="lang"
            onChange={handleChangeLocale}
            value={language}
          >
            {languages.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-slate-400 ocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
            onClick={onClose}
          >
            {t('buttons_navigation.button_agree_ok')}
          </button>
        </div>
      </div>
    </div>
  );
};