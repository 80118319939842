import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { Loading } from '../components';
import { useEvents, useHandleSubmit } from '../hooks';
import { createResource } from '../services/farmApi';
import _ from "lodash"; 

export const GdfAiForm = () => {

    // for language translation
    const { t } = useTranslation();
    const { user } = useUser();  // Get currently loggedIn user
    const {envState, setEnvState} = useEnv(); // Get Environment context
    const [userInput, setUserInput] = useState("");
    const [chatHistory, setChatHistory] = useState([
      { role: "user", message: t('home_page.home_page_title') },
      { role: "bot", message: t('ai_page.hello_great_to_meet_you_message') }
    ]);

    const [isLoading, setIsLoading] = useState(false);
    
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState('');
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler
    const today = new Date().toISOString().split('T')[0]; // Todays' date
    

    const initFormState = {  
        aiAppDate: today, 
        user_id: user.id,
        aiUserInput: '',
        aiError: '',
    }; // Initial Form state
    const [formState, setFormState] = useState(initFormState);
    const resetFormState = () => { setFormState(initFormState) }

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_ask_gdf_ai'), backPath: "/fe/manage", homePath: "/fe"}));
        return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []);

    // initialize Gemini API
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_GEN_AI_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    // function to send interactive user input to Gemini
    const sendInteractiveChat = async (e) => {
      e.preventDefault();
      if (userInput.trim() === "") return;

      setIsLoading(true);
      let caughtError = null;

      try {
        // Rebuild the chat history for the model
        const historyForAI = chatHistory.map(item => ({
          role: item.role === "user" ? "user" : "model",
          parts: [{ text: item.message }]
        }));

        // Start a new chat with the updated history
        const chatSession = model.startChat({ history: historyForAI });

        // Send the user's message to the AI
        let result = await chatSession.sendMessage(userInput);

        if (result && result.response) {
          const botResponse = await result.response.text();
          // console.log(botResponse);

          // Update the chat history in state
          setChatHistory(prevHistory => [
            ...prevHistory,
            { role: "user", message: userInput },
            { role: "bot", message: botResponse }
          ]);
        } else {
          console.error("No response from AI model", result);
        }
      } catch (error) {
        caughtError = error;
        console.error("Error sending chat message:", error);
      } finally {
        setIsLoading(false);
        setUserInput(""); // Clear input field after sending

        // Update formState with AI data for logging
        formState.aiUserInput = userInput;
        formState.aiError = caughtError;
        
        // Perform logging separately
        logAIRequest(e, formState);
      }
    };

    // Function to log AI request to the database
    const logAIRequest = async (e, formState) => {
      try {
        await handleSubmit(
          e,
          formState,
          createResource, 
          envState.online,
          process.env.REACT_APP_AUTH_AI_USERS,
          setResponseErrors,
          setCreateNotice,
          resetFormState,
          publish
        );
      } catch (error) {
        console.error("Error logging AI request:", error);
      }
    };
    
    // Throttle the API call to limit to 1 call every 30 seconds
    const throttledApiCall = _.throttle((e) => {

      
      // Call the function that handles the API call, no async here
      sendInteractiveChat(e); 
    }, 30000, { trailing: false }); // 30 seconds, with no trailing call

    // Event handler for form submission
    const handleFormSubmit = (e) => {
      // console.count("Form submit event count");
      // console.log("Form submit event triggered at: ", new Date().toLocaleTimeString('en-US', { 
      //   hour12: false, 
      //   hour: '2-digit', 
      //   minute: '2-digit', 
      //   second: '2-digit' }));
      throttledApiCall(e);  // Using throttled function for the event handler
    };
  
    // constant to handle user input
    const handleUserInput = (e) => {
      setUserInput(e.target.value);
    };

    // function to clear the chat history and initialize it with default messages
    const clearChat = () => {
      setChatHistory([
        { role: "user", message: t('ai_page.clear_chat') },
        { role: "bot", message: t('ai_page.cleared_chat_ask_again') },
      ]);
    };


    return (
      <div className="container mx-auto px-4 py-8 mt-16">
        <div className="lg:col-span-1">{/*spacer*/}</div>
        <div>
          <center>
            <h5>{t('ai_page.ai_disclaimer_title')}</h5>
          </center>
        </div>
    
        {/* Chat container */}
        <div className="chat-container rounded-lg shadow-md bg-blue-100 p-4">
          {/* Render chat history */}
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`message ${chat.role} ${
                chat.role === "user"
                  ? "bg-gray-100 text-gray-800"
                  : "bg-blue-100 text-blue-800"
              } py-2 px-4 rounded-lg flex items-start`}
            >
              {chat.role === "user" && (
                <span className="mr-2 font-bold text-gray-600">
                  {t('user_handle_notice.person_addressed_as_you')}
                </span>
              )}
              <div className="inline-block">{chat.message}</div>
            </div>
          ))}
          <center>
            {isLoading && <Loading />} {/* Conditionally rendering Loading */}
          </center>
        </div>
    
        {/* Form and input */}
        <div className="flex flex-col sm:flex-row sm:items-center mt-4">
          {/* <form onSubmit={sendInteractiveChat} className="flex-grow flex flex-col sm:flex-row sm:items-center"> */}
          <form onSubmit={handleFormSubmit} className="flex-grow flex flex-col sm:flex-row sm:items-center">
            <input
              type="text"
              className="flex-grow px-4 py-2 mb-4 sm:mb-0 sm:mr-2 rounded-lg border border-gray-300 text-gray-900 ring-1 ring-inset ring-slate-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
              placeholder={t('user_handle_notice.type_your_message')}
              value={userInput}
              onChange={handleUserInput}
            />
            <button
              className="px-4 py-2 rounded-lg bg-slate-500 text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
              type="submit"
              disabled={isLoading}
            >
              {t('ai_page.ask_ai')}
            </button>
          </form>
        </div>
    
        {/* Clear chat button */}
        <button
          className="mt-4 w-full sm:w-auto px-4 py-2 rounded-lg bg-gray-400 text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
          onClick={clearChat}
        >
          {t('ai_page.clear_ai_chat')}
        </button>
      </div>
    );

}