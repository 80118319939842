import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { TextInput, InputLabel, SelectInput , Footer} from '../components'
import { useState, useLayoutEffect, useEffect } from 'react';
import { createResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';

export const WashForm = () => {

  // for language translation
  const { t } = useTranslation();

  const { user } = useUser();  // Get currently loggedIn user
  const { envState, setEnvState } = useEnv(); // Get Environment context
  const { publish } = useEvents(); // publish - dispatches events
  const { handleSubmit } = useHandleSubmit(); // Shared submit handler  
  const today = new Date().toISOString().split('T')[0]; // Todays' date
// --------------------- start deciding how to handle queued record if any -------------------

const [queuedRecord, setFarmRecord] = useState(null);
const [initFormState, setInitFormState] = useState(null);

useEffect(() => {
    // Retrieve record from local storage using the special key if was a failed queue
    // const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
    const queuedFarmRecord = localStorage.getItem(`queuedFarmRecord_${user.id}`);
    
    if (queuedFarmRecord) {
        const parsedFarmRecord = JSON.parse(queuedFarmRecord);
        setFarmRecord(parsedFarmRecord);

        // Delete the special key and its corresponding data from local storage
        // localStorage.removeItem('queuedFarmRecord');
        localStorage.removeItem(`queuedFarmRecord_${user.id}`);
        
    }
}, []);


useEffect(() => {
    if (queuedRecord) {
        // If queueRecord exists, initialize initFormState with its data
        setInitFormState({ ...queuedRecord, user_id: user.id });

    } else {
        // If queueRecord is null, initialize initFormState with default values
        setInitFormState({
          cattle_id: '',
          date: today,
          wash_type: 'Pump',
          chemical: '',
          bulk_single: "Single",
          user_id: user.id,
        });
    }
}, [queuedRecord]);

// ensuring initFormState is initialized before rendering a page
useEffect(() => {
      if (initFormState !== null) {
        setFormState(initFormState);
      }
}, [initFormState]);

const resetFormState = () => { 
  setFormState(initFormState);
  setFarmRecord(null);
}

const [formState, setFormState] = useState(initFormState);

// --------------------- end deciding how to handle queued record if any -------------------

  //   const [formState, setFormState] = useState(initFormState);
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState('');

    //const washTypes = useRef([['Pump', t('manage_wash_page.wash_pump')], ['Other', t('manage_wash_page.wash_other')]]);

    const textInputStyle = useEnaDisableStyle();
    // Takes in array off validation errors and returns jsx.
    const showFieldErrors = useErrorParser();

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_wash'), backPath: "/fe/home", homePath: "/fe"}));
      return () => {
        setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []);

    // I want to handle Bulk seeded cattle in this selected. this is a new version that handles Bulk option
    const handleChange = (inputKey, inputValue) => {
      if (inputKey === 'bulk_single') {
        if (inputValue === 'Bulk') {

          setFormState({ ...formState, [inputKey]: inputValue, cattle_id: 'Bulkcattle' });

        } else if (inputValue === 'Single') {
          
          setFormState({ ...formState, [inputKey]: inputValue, cattle_id: '' });

        }
      } else {
        
        setFormState({ ...formState, [inputKey]: inputValue });
      }
    };



    if (initFormState === null) {
      // : see how you get this properly
      return <div>{t("error_message.loading")}</div>; // or any loading indicator
    }

    return (
        <div className="mt-[100px] mb-[100px]">
            <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <form
                        onSubmit={(e) => {
                          handleSubmit(
                            e,
                            formState,
                            createResource,
                            envState.online,
                            process.env.REACT_APP_WASHES,
                            setResponseErrors,
                            setCreateNotice,
                            resetFormState,
                            publish
                          )

                        }}
                        autoComplete="off"
                    >
                        <div className="overflow-hidden sm:rounded-md">
                          <div className="bg-white px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">

                                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <InputLabel
                                      inputId="bulk_single_label"
                                      inputStyle="block text-sm font-medium text-gray-700"
                                      labelText={t("navigation_element.nav_item_title_select_bulk_single")} 
                                    />
                                    <SelectInput
                                      focus={false}
                                      inputName="bulk_single"
                                      inputId="bulk_single"
                                      inputStyle={textInputStyle.current.enabledInput}
                                      inputValue={formState?.bulk_single || 'Single'}
                                      handleChange={handleChange}
                                      choices={[
                                        { key: "Single", label: t("navigation_element.nav_item_title_single") }, 
                                        { key: "Bulk", label: t("navigation_element.nav_item_title_bulk")  }, 
                                      ]}
                                    />
                                </div>


                                {/** // I want to handle Bulk seeded cattle in this selected. this is a new version that handles Bulk option */}
                                {/* Cattle Id field - text*/}
                                {formState?.bulk_single === 'Single' && (
                                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <InputLabel
                                      inputId="cattle_id"
                                      inputStyle="block text-sm font-medium text-gray-700"
                                      labelText={t("page_elements.tag")}
                                    />
                                    <TextInput
                                      focus={true}
                                      inputType="text"
                                      inputName="cattle_id"
                                      inputId="cattle_id"
                                      hint={t("milking_page.enter_cattle_tag")}
                                      inputPattern="^[a-zA-Z0-9]{1,10}$"
                                      isAutoComplete="on"
                                      inputStyle={textInputStyle.current.enabledInput}
                                      inputValue={formState?.cattle_id || ''}
                                      handleChange={handleChange}
                                    />
                                    {showFieldErrors(responseErrors.cattle_id)}
                                  </div>
                                )}

                                {/* Wash Date - date */}
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="date"
                                    inputStyle="block text-sm font-medium text-gray-700"
                                    labelText={t('manage_wash_page.wash_date')}
                                  />
                                  <TextInput
                                    focus={false}
                                    inputType="date"
                                    inputName="date"
                                    inputId="date"
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState?.date}
                                    handleChange={handleChange}
                                  />
                                  {showFieldErrors(responseErrors.date)}
                                </div>
                                {/* Wash type - select*/}
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <InputLabel
                                        inputId="wash_type"
                                        inputStyle="block text-sm font-medium text-gray-700"
                                        labelText={t('manage_wash_page.wash_type')}
                                    />
                                    <SelectInput
                                      focus={false}
                                      inputName="wash_type"
                                      inputId="wash_type"
                                      inputStyle="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      inputValue={formState?.wash_type}
                                      handleChange={handleChange}
                                      choices={[
                                        { key: 'Pump', label: t('manage_wash_page.wash_pump') },
                                        { key: 'Other', label: t('manage_wash_page.wash_other') },
                                      ]}
                                    />
                                  {showFieldErrors(responseErrors.wash_type)}
                                </div>
                                {/* Chemical used - text */}
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="chemical"
                                    inputStyle="block text-sm font-medium text-gray-700"
                                    labelText={t('manage_wash_page.wash_chemical_used')}
                                  />
                                  <TextInput
                                    focus={false}
                                    inputType="text"
                                    inputName="chemical"
                                    inputId="chemical"
                                    hint={t('manage_wash_page.wash_enter_chemical_used')}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState?.chemical}
                                    handleChange={handleChange}
                                  />
                                  {showFieldErrors(responseErrors.chemical)}
                                </div>
                            </div>
                          </div>
                          <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
  );


}