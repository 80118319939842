import { TextInput, InputLabel, SelectInput, TextareaInput, Footer, NumberInput, BottomNavigation } from '../components';
import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { toast, ToastContainer } from 'react-toastify';
import { createResource } from '../services/farmApi';
import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { ConsoleLogEvent } from '../utils';


const MarketPlaceChatForm = () => {
    // for language translation
    const { t } = useTranslation();
    const {envState, setEnvState } = useEnv(); // Get Environment context

    const { user } = useUser();  // Get currently loggedIn user
    const today = new Date().toISOString().split('T')[0]; // Todays' date
    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler

    // const [fileBytesSize, setFileBytesSize] = useState(0);
    // const [fileName, setFileName] = useState('');
    // const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState(null); // Error message state

    // ------------------ start refactor -------------------------
    const [initFormState, setInitFormState] = useState(null);
    const resetFormState = () => { setFormState(initFormState) }
      
    // Setting initial form state in useEffect
    useEffect(() => {
        const initialState = {
            user_id: user.id, // assuming user is defined in the scope
        };

        setInitFormState(initialState);
        setFormState(initialState); // Set formState when initFormState is set
    }, []);

    
    const [formState, setFormState] = useState({
        user_id: user.id,  // Assuming `user.id` is defined
    });

    // This effect will log the formState whenever it changes
    useEffect(() => {
            
        
    }, [formState]); // This will log formState whenever it changes

    const submitRequestForm = async (e) =>  {
        e.preventDefault();

        // Clear previous errors before validation
        setResponseErrors({});


        ConsoleLogEvent('info', 'Marketplace Chat coming soon....');
        // submitForm(e, formState);
 
    };

    // Submit form with necessary parameters
    const submitForm = (e, formState) => {
        e.preventDefault(); // Prevent form from reloading the page

        // Submit form using handleSubmit, passing formState and necessary parameters
        handleSubmit(
            e,
            formState,  // pass formState with base64 and media details
            createResource,
            envState.online,
            process.env.REACT_APP_MARKET_PLACE,
            setResponseErrors,
            setCreateNotice,
            resetFormState,
            publish,
            t 
        );

        // Display success message and reset form
        toast.success(t('market_place_page.item_uploaded_users_notified'));
    };
    
    return (
        <div className="container">
            <div>Coming Soon Market Place Chat</div>
            <form onSubmit={submitRequestForm}>



                <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="submit"/>
            </form>
            <ToastContainer />
                       {/* Bottom Navigation */}
                       {/* <BottomNavigation /> */}
        </div>
    );
};

export { MarketPlaceChatForm };
