import { ConsoleLogEvent } from "./ConsoleLogEvent";

function loadFacebookSDK(facebookApiKey) {

    return new Promise((resolve) => {
      if (window.FB) {
        ConsoleLogEvent('info', 'Facebook SDK already loaded');
        resolve();
        return;
      }
  
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: facebookApiKey,
          cookie: true,
          xfbml: true,
          version: 'v21.0',
        });
        window.FB.AppEvents.logPageView();
        resolve();
      };
  
      const script = document.createElement('script');
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      document.body.appendChild(script);
    });
  }

export { loadFacebookSDK }