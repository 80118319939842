// ------------------------------
// Import the useTranslation hook here
import { useTranslation } from "react-i18next";
import { queuePost } from "../services/queue";

const handleSubmit = (
  event,
  formData,
  getApiService,
  isOnline,
  reqUrl,
  setErrors,
  setNotice,
  resetForm,
  dispatchEvent,
  t
) => {
  event.preventDefault();
  if (isOnline) {
    // App thinks it is online
    // Turn on isSubmitting flag
    setNotice({ ...formData, isSubmitting: true });
    getApiService(reqUrl, formData)
      .then((resp) => {
        if (resp.success) {
          setErrors({});
          setNotice({
            showNotice: true,
            succ: true,
            status: 200,
            msg: t("user_handle_notice.new_record_created"), // use can use status code for dynamic translation
            isSubmitting: false,
          });
          resetForm();
        } else {
          // resp.errors && setErrors(resp.errors);
          // setNotice({showNotice: true, succ: false, msg: `Failed to create a new record, ${resp.status}, ${resp.message}`});
          if (resp.status === 101) {
            setNotice({
              showNotice: true,
              succ: false,
              status: 200,
              msg: t("user_handle_notice.unable_to_reach_internet"),
              isSubmitting: false,
            });
          } else if (resp.status === 422) {

            // use can use error code instead this is temporary hardcoding translation of this message
            if (resp.errors && resp.errors.cattle_id && resp.errors.cattle_id.length > 0) {
              const errorMessage = resp.errors.cattle_id[0];
              if (errorMessage === "The selected cattle id is invalid.") {
                // Modify the resp.errors object
                resp.errors.cattle_id[0] = t('user_handle_notice.cattle_id_not_registered');
              }
            }
            resp.errors && setErrors(resp.errors);
            // setNotice({showNotice: true, succ: false, status: 422, msg: `Failed to create a new record, ${resp.status}, ${resp.message}`});
            setNotice({
              showNotice: true,
              succ: false,
              status: 422,
              msg: t("user_handle_notice.failed_to_create_new_record"),
              isSubmitting: false,
            });
          } else if (resp.status === 401) {
            setNotice({
              showNotice: true,
              succ: false,
              status: 401,
              msg: t("user_handle_notice.not_authorized_to_retrieve_record"),
              isSubmitting: false,
            });
          } else if (resp.status === 404) {
            setNotice({
              showNotice: true,
              succ: false,
              status: 404,
              msg: t("user_handle_notice.record_barred"),
              isSubmitting: false,
            });
          }else if (resp.status === 500) {
            setNotice({
              showNotice: true,
              succ: false,
              status: 500,
              msg: t("user_handle_notice.app_unavailable"),
              isSubmitting: false,
            });
          }else if (resp.status === 503) {
            setNotice({
              showNotice: true,
              succ: true,
              status: 503,
              msg: 't("market_place_page.marketplace_maintenance_message")',
              isSubmitting: false,
            });
          }
        }
      })
      .catch((error) => {
        setNotice({
          showNotice: true,
          succ: false,
          status: 101,
          msg: 't("user_handle_notice.app_experiencing_problems")',
          isSubmitting: false,
        });
      });
  } else {
    // App thinks its offline
    const resp = queuePost(reqUrl, formData, t); // added t for translation purposes
    if (resp.success) {
      setNotice({
        showNotice: true,
        succ: true,
        status: 200,
        //  translate: custom messages are coming back from the server
        //       with this one, you need to pass resp.code and use this for fe translation
        msg: `${resp.message}`,
        isSubmitting: false,
      });
      dispatchEvent("queueing"); // Dispatch request-queue-change event.
      resetForm();
    }
  }
};

const handleGetResource = (
  formData,
  getApiService,
  isOnline,
  reqUrl,
  setNotice,
  resetForm,
  setData,
  setIsSearching,
  isSingle = true,
  t
) => {
  if (isOnline) {
    // App thinks it is online
    setIsSearching !== null && setIsSearching(true);
    getApiService(reqUrl, formData)
      .then((resp) => {
        setIsSearching !== null && setIsSearching(false);
        if (resp.success) {
          // Single resouce comes in a an object
          isSingle ? setData([resp.data]) : setData(resp.data);
          setNotice({ showNotice: false, succ: true, status: 200, msg: "" });
          resetForm !== null && resetForm();
        } else {
          if (resp.status === 101) {
            setNotice({
              showNotice: true,
              succ: false,
              ver: Math.random(),
              status: 101,
              msg: t("user_handle_notice.unable_to_reach_internet"),
            });
          } else if (resp.status === 404) {
            // Return the query string and status.
            // setData([{ver: Math.random(), status: 404, queryString: resp.data}]);
            if (isSingle) {
              setNotice({
                showNotice: true,
                succ: false,
                ver: Math.random(),
                status: 404,
                queryString: resp.data,
                msg: t("user_handle_notice.record_not_found"),
              });
            } else {
              setNotice({
                showNotice: true,
                succ: false,
                ver: Math.random(),
                status: 404,
                msg: t("user_handle_notice.record_not_found"),
              });
            }
          } else if (resp.status === 401) {
            setNotice({
              showNotice: true,
              succ: false,
              ver: Math.random(),
              status: 401,
              msg: t("user_handle_notice.not_authorized_to_retrieve_record"),
            });
          } else if (resp.status === 403) {
            setNotice({
              showNotice: true,
              succ: false,
              ver: Math.random(),
              status: 403,
              msg: t("user_handle_notice.forbiden_to_retrieve_record"),
            });
          } else if (resp.status === 500) {
            setNotice({
              showNotice: true,
              succ: false,
              ver: Math.random(),
              status: 500,
              msg: t("user_handle_notice.app_unavailable"),
            });
          }
        }
      })
      .catch((error) => {
        setIsSearching(false);
        setNotice({
          showNotice: true,
          succ: false,
          ver: Math.random(),
          status: 101,
          msg: t("user_handle_notice.unable_to_reach_internet"),
        });
      });
  } else {
    // App thinks its offline
    setIsSearching !== null && setIsSearching(false);
    setNotice({
      showNotice: true,
      succ: false,
      ver: Math.random(),
      status: 101,
      msg: t("user_handle_notice.unable_to_reach_internet"),
    });
  }
};

export const useHandleSubmit = () => {
  const { t } = useTranslation(); // Use the hook here

  // Return the functions along with the useTranslation result
  return {
    handleSubmit: (
      event,
      formData,
      getApiService,
      isOnline,
      reqUrl,
      setErrors,
      setNotice,
      resetForm,
      dispatchEvent
    ) =>
      handleSubmit(
        event,
        formData,
        getApiService,
        isOnline,
        reqUrl,
        setErrors,
        setNotice,
        resetForm,
        dispatchEvent,
        t
      ),

    handleGetResource: (
      formData,
      getApiService,
      isOnline,
      reqUrl,
      setNotice,
      resetForm,
      setData,
      setIsSearching,
      isSingle = true
    ) =>
      handleGetResource(
        formData,
        getApiService,
        isOnline,
        reqUrl,
        setNotice,
        resetForm,
        setData,
        setIsSearching,
        isSingle,
        t
      ),
  };
};



