import { useEnaDisableStyle, useErrorParser, useHandleSubmit } from '../hooks';
import { getResource, guestCreateResource } from '../services/farmApi';
import React, { useLayoutEffect, useState, useEffect} from 'react';
import { TextInput, InputLabel, Footer } from '../components'
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import TabNavItem from "../components/forms/TabNavItem";
import TabContent from "../components/forms/TabContent";
import { ToastContainer, toast } from "react-toastify";
import { eye } from 'react-icons-kit/feather/eye';
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { Icon } from 'react-icons-kit';
import "../tabsview.css";

import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

import { ConsoleLogEvent } from '../utils';


function ManageProfileForm(){

    const { user, setUser } = useUser();  // Get currently loggedIn user

    // for language translation
    const { t, i18n } = useTranslation();
    const {envState, setEnvState } = useEnv(); // Get Environment context

    const today = new Date().toISOString().split('T')[0]; // Todays' date
    const initFormState = {  
        to_day_date: today, 
        email: '',
        password: '',
        passwordPair: '',
        farm_name: '',
        farm_address: '',
        country_name: '',
        farm_contact_number: '',
        person_contact_number: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        profile_type: '',
    }; // Initial Form state
    const [formState, setFormState] = useState(initFormState);
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    const [responseErrors, setResponseErrors] = useState({});
    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const navigate = useNavigate();
    
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("tab1");

    useEffect(() => {
        // Check if a specific tab was passed in the navigation state
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab);
        }
    }, [location.state]);


    // Notifiy the App of this location and Header Nav requirements t('navigation_element.nav_item_title_transactions')
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_manage_profile'), backPath: "/fe/manage", homePath: "/fe"}));
  
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    // const handleChange = (inputKey, inputValue) => {

    //     setPassword(inputValue);
    //     setFormState({...formState, [inputKey]: inputValue});

    //     // ConsoleLogEvent('info', 'Handle change formState value set: ', formState);
    //     setActiveTab(id);

    //     setResponseErrors({});
    // }

    const handleChange = (inputKey, inputValue) => {
        // Handle tab switching logic
        if (["tab1", "tab2", "tab3"].includes(inputKey)) {
            setActiveTab(inputKey); // Update the active tab
        } else {
            // Handle form state updates
            setPassword(inputValue);
            setFormState({ ...formState, [inputKey]: inputValue });
        }
    
        // Reset errors (this applies to both cases)
        setResponseErrors({});
    };

    const resetFormState = () => { setFormState(initFormState) }
    
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler


    const [reportFarmData, setFarmData] = useState([]);

    const [phoneValue, setPhoneValue] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryName, setCountryName] = useState("");
    const [phoneError, setPhoneError] = useState(null);

    const handlePhoneChange = (value) => {

        setPhoneValue(value);
        setPhoneError(null);

        if (!value) {
            setPhoneError(t('error_message.phone_number_required')); 
            return;
        }
        
        try {
            const phoneNumber = parsePhoneNumberFromString(value);
    
            // Check if the phone number is complete and valid before using it
            if (phoneNumber && phoneNumber.isPossible() && phoneNumber.isValid()) {

                setCountryCode(phoneNumber.countryCallingCode);
                setCountryName(phoneNumber.country);                

            } else {
                setPhoneError(t('error_message.incorrect_invalid_number')); 
                // reset phone value
                setPhoneValue('');
            }
        } catch (error) {
            // Handle the parsing error
            ConsoleLogEvent('error', 'Error parsing phone number:', error.message);
        }

    };

    // Log the updated values after they are set
    useEffect(() => {
        if (phoneValue && countryCode && countryName) {

            formState.country_name = countryName;
            formState.farm_contact_number = phoneValue;
            formState.person_contact_number = phoneValue;

        }
    }, [phoneValue, countryCode, countryName]);

    useEffect(() => {
        const fetchDataAndUpdateReportFarmData = async () => {
            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_AUTH_MANAGE_PROFILE,
                    setCreateNotice,
                    null,
                    setFarmData,
                    null,
                    false // explicitly expecting a single model?
                );
            } catch (error) {
                ConsoleLogEvent('error', 'Error fetching farm data:', error);
            }
        };
    
        // Fetch data and update reportFarmData
        fetchDataAndUpdateReportFarmData();
    }, []);

    const handlePopulateFarmValues = (e) => {
        setFormState({
            ...formState,
            farm_name: reportFarmData?.farm_record?.farm_name ?? '',
            farm_address: reportFarmData?.farm_record?.address ?? '',
            country_name: reportFarmData?.farm_record?.country ?? '',
            farm_contact_number: reportFarmData?.farm_record?.phone_number ?? ''
        });
    }

    const handlePopulatePersonalValues = (e) => {
        setFormState({
            ...formState,
            first_name: reportFarmData?.personal_record?.first_name ?? user?.firstName,
            middle_name: reportFarmData?.personal_record?.middle_name ?? '',
            last_name: reportFarmData?.personal_record?.last_name ?? user?.lastName,
            person_contact_number: reportFarmData?.personal_record?.phone_number ?? ''
        });
        
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }; // Regular expression for validating email addresses

    // const isValidPhone = (phoneNumber) => {
    //     // This regex will match phone numbers with 11 or 12 digits that do not start with "00", "0", or "+".
    //     const isValidInput = /^(?!00|\+|0)\d{11,12}$/.test(phoneNumber);
    //     return isValidInput;
    // }; // Regular expression for validating email addresses

    const submitPasswordResetProcessor = async (e) => {
        e.preventDefault(); 
        
        if (formState.email === ''){
            setResponseErrors({email: [t('login_register_page.enter_email_address')]});   
            return;
        }
        
        if (!isValidEmail(formState.email.trim())) {
            setResponseErrors({ email: [t('login_register_page.enter_valid_email_address')] });   
            return;
        }

        if (formState.password === '') {
            setResponseErrors({password: [t('login_register_page.enter_password')]});    
            return;
        }

        if(formState.passwordPair === ''){
            setResponseErrors({passwordPair: [t('login_register_page.re_type_password')]});    
            return;
        }

        if (formState.password.length < 5) {
            setResponseErrors({password: [t('login_register_page.password_length')]});    
            return;
        }      

        if (formState.password !== formState.passwordPair) {
            setResponseErrors({passwordPair: [t('error_message.mismatch_password')]});  
            return;
        }

        const checkbox = document.getElementById('privacypolicy');
        if (!checkbox.checked) {
            setResponseErrors({privacypolicy: [t('terms_and_conditions.accept_privacy_policy')]});   
            return;
        } // Request a check if the checkbox is checked

        if (formState.email !== user.email) {
            setResponseErrors({email: [t('login_register_page.incorrect_logged_email')]});  
            return;
        }

        setResponseErrors({});
        checkbox.checked = false; // Uncheck the checkbox

        const regResp = await guestCreateResource(
            process.env.REACT_APP_AUTH_CHANGE_PASSWORD,
            {
                profile_type: 'CHANGE_PASSWORD_PROFILE',
                user_id: user.id,
                user_email: formState.email,
                user_password: formState.password,
                user_pair_password: formState.passwordPair
            },
            t
        );

        // 401 Passwords do not match 
        // 404 User not found or inactive
        // 200 Password change processed successfully
        if (regResp.success) {
            resetFormState();

            // Show Success Notificaton screen
            showToast(t('login_register_page.password_changed'), 'info');
            // Navigate to Manage page
            navigate('/fe');
            return;
        }else{
           // Show Error Notificaton screen
           showToast(t('login_register_page.password_not_changed'), 'error');
           return;           
        }
    }

    const submitFarmRecords = async (e) => {
        e.preventDefault(); 

        const NOT_FARM_OWNER = '0';
        if (reportFarmData.farm_record.status === NOT_FARM_OWNER) {
            showToast(t('manage_profile_page.farm_owner_only'), 'error');
            // redirect to fe
            navigate('/fe');
            return;
        }

        if (formState.farm_name === ''){
            setResponseErrors({farm_name: [t('manage_profile_page.fill_farm_name')] });  
            return;
        }

        if (formState.farm_address === ''){
            setResponseErrors({farm_address: [t('manage_profile_page.fill_farm_address')] }); 
            return;
        }

        if (formState.country_name === ''){
            setResponseErrors({country_name: [t('manage_profile_page.fill_country_name')] }); 
            return;
        }

        if (formState.farm_contact_number === ''){
            setResponseErrors({farm_contact_number: [t('manage_profile_page.fill_farm_phone')] });  
            return;
        }

        // if (!isValidPhone(formState.farm_contact_number.trim())) {
        //     setResponseErrors({ farm_contact_number: [t('error_message.invalid_phone_number')] });  
        //     return;
        // }

        // if all good then 
        setResponseErrors({});
        const regResp = await guestCreateResource(
            process.env.REACT_APP_AUTH_CHANGE_FARM_PROFILE,
            {
                profile_type: 'CHANGE_FARM_PROFILE',
                user_id: user.id,
                user_email: user.email,
                farm_name: formState.farm_name,
                farm_address: formState.farm_address,
                country_name: formState.country_name,
                farm_contact_number: formState.farm_contact_number,
            },
            t
        );

        // 401 Passwords do not match 
        // 404 User not found or inactive
        // 200 Password change processed successfully
        if (regResp.success) {
            resetFormState();

            // Show Success Notificaton screen
            showToast(t('manage_profile_page.farm_records_updated'), 'info'); 
            // Navigate to Manage page
            navigate('/fe');
            return;
        }else{
           // Show Error Notificaton screen
           showToast(t('manage_profile_page.farm_records_not_updated'), 'error'); 
           return;           
        } 
    }

    const submitPersonalRecords = async (e) => {
        e.preventDefault(); 

        ConsoleLogEvent('info', 'Data to be processed as submitPersonalRecords with formState: ', formState);

        // validate input values
        if (formState.first_name === ''){
            setResponseErrors({first_name: [t('manage_users_page.enter_first_name')]});    
            return;
        }

        // if (formState.middle_name === ''){
        //     setResponseErrors({middle_name: [t('manage_users_page.enter_middle_name')]});   
        //     return;
        // }

        if (formState.last_name === ''){
            setResponseErrors({last_name: [t('manage_users_page.enter_last_name')]});   
            return;
        }

        if (formState.person_contact_number === ''){
            setResponseErrors({personal_contact_number: [t('error_message.fill_your_phone_number')] });  
            return;
        }

        // if all good then 
        setResponseErrors({});
        const regResp = await guestCreateResource(
            process.env.REACT_APP_AUTH_CHANGE_PERSONAL_PROFILE,
            {
                profile_type: 'CHANGE_PERSONAL_PROFILE',
                user_id: user.id,
                user_email: user.email,
                first_name: formState.first_name,
                middle_name: formState.middle_name,
                last_name: formState.last_name,
                person_contact_number: formState.person_contact_number,
            },
            t
        );

        // 401 Passwords do not match 
        // 404 User not found or inactive
        // 200 Password change processed successfully
        if (regResp.success) {
            resetFormState();

            // it is successful here, if person_contact_number is not null or empty
            // update user with phoneNumber = person_contact_number


            // Update the user object if person_contact_number is not null or empty
            if (formState.person_contact_number) {
                setUser((prevUser) => ({
                    ...prevUser, // Keep other user details intact
                    phoneNumber: formState.person_contact_number, // Update the phone number
                }));
            }


            // Show Success Notificaton screen
            showToast(t('manage_profile_page.personal_records_updated'), 'info');    
            // Navigate to Manage page
            navigate('/fe');
            return;
        }else{
           // Show Error Notificaton screen
           showToast(t('manage_profile_page.personal_records_not_updated'), 'error');   
           return;           
        } 
    }

    // Function to open the popup
    const openPrivacyPopup = () => {
        // URL of the terms and conditions page
        let termsURL = "https://www.gdf.co.tz"; // Default URL

        // Adjust the URL based on the language
        if (i18n.language === 'en') {
            // termsURL = "https://www.gdf.co.tz/terms/en.html"; // English terms and conditions page URL
            termsURL = "https://www.gdf.co.tz/privacy/en.html"; // English privacy policy page URL
        } else if (i18n.language === 'sw') {
            // termsURL = "https://www.gdf.co.tz/terms/sw.html"; // Swahili terms and conditions page URL
            termsURL = "https://www.gdf.co.tz/privacy/sw.html"; // Swahili privacy policy page URL
        }
        
        // Open a new popup window with the terms and conditions page
        window.open(termsURL, "_blank", "width=400,height=900");
    };

    const showToast = (message, type) => {
        toast[type](message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };

    const [ password, setPassword ] = useState("");
    const [type, setType] = useState('password');
    const [typePair, setTypePair] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [iconPair, setIconPair] = useState(eyeOff);


    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text');
            // Ensure the other input is in password mode and has the eyeOff icon
            if (typePair !== 'text') {
                setTypePair('password');
                setIconPair(eyeOff);
            }
        } else {
            setIcon(eyeOff);
            setType('password');
        }
    }
    
    const handleTogglePair = () => {
        if (typePair === 'password') {
            setIconPair(eye);
            setTypePair('text');
            // Ensure the other input is in password mode and has the eyeOff icon
            if (type !== 'text') {
                setType('password');
                setIcon(eyeOff);
            }
        } else {
            setIconPair(eyeOff);
            setTypePair('password');
        }
    }
    
    return (
        <center>
          <div className="mt-[100px] mb-[100px]">
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="mt-5 md:col-span-2 md:mt-0">
                  <ul className="nav">
                    <TabNavItem 
                      title={t('manage_profile_page.password_update')}
                      id="tab1"
                      activeTab={activeTab} 
                      setActiveTab={setActiveTab}
                      handleChange={() => handleChange("tab1", "tab1")}
                    />
                    <TabNavItem 
                        title={t('manage_profile_page.farm_update')}
                        id="tab2" 
                        activeTab={activeTab} 
                        setActiveTab={setActiveTab}
                        handleChange={() => handlePopulateFarmValues()}
                    />
                    <TabNavItem 
                        title={t('manage_profile_page.personal_update')}
                        id="tab3" 
                        activeTab={activeTab} 
                        setActiveTab={setActiveTab}
                        handleChange={() => handlePopulatePersonalValues()}
                    />
                  </ul>
            
                  <div className="outlet">
                    {/* Change Password Tab */}
                    <TabContent id="tab1" activeTab={activeTab}>
                        <div className="mt-[0px] mb-[100px] p-6 md:p-0 ">
                            <div className="grid grid-cols-26">
                                <div className="mt-0 md:col-span-2 md:mt-0">
                                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl gray:text-white">
                                    {t('manage_profile_page.change_password')}
                                    </h2>
                                    <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={submitPasswordResetProcessor} autoComplete="off" noValidate>
                                        {/* Email Address */}
                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <InputLabel
                                                inputId="email"
                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                labelText={t('login_register_page.your_email')}
                                            />
                                            <TextInput
                                                focus={false}
                                                inputType="email"
                                                inputName="email"
                                                inputId="email"
                                                isAutoComplete="on"
                                                hint={t('manage_profile_page.name_at_company_dot_com')}
                                                isRequired={false}
                                                isDisabled={false}
                                                inputStyle={textInputStyle.current.enabledInput}
                                                inputValue={formState.email}
                                                handleChange={handleChange}
                                            />
                                            <div className="response-error-message">
                                                {showFieldErrors(responseErrors.email)}
                                            </div>
                                        </div>
                                        {/* New Password */}
                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <InputLabel
                                                inputId="password"
                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                labelText={t('login_register_page.new_password')}
                                            />
                                            <div className="mb-4 flex">
                                                <TextInput
                                                    focus={false}
                                                    inputType={type}
                                                    inputName="password"
                                                    inputId="password"
                                                    isAutoComplete="off"
                                                    hint="••••••••"
                                                    isRequired={false}
                                                    isDisabled={false}
                                                    inputStyle={textInputStyle.current.enabledInput}
                                                    inputValue={formState.password}
                                                    handleChange={handleChange}
                                                />
                                                <span className="flex justify-around items-center" onClick={handleToggle}>
                                                    <Icon className="absolute mr-10" icon={icon} size={25}/>
                                                </span>
                                            </div>
                                            <div className="response-error-message">
                                                {showFieldErrors(responseErrors.password)}
                                            </div>
                                        </div>
                                        {/* Confirm Password */}
                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <InputLabel
                                                inputId="passwordPair"
                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                labelText= {t('login_register_page.confirm_password')}
                                            />
                                            <div className="mb-4 flex">
                                                <TextInput
                                                    focus={false}
                                                    inputType={typePair}
                                                    inputName="passwordPair"
                                                    inputId="passwordPair"
                                                    isAutoComplete="off"
                                                    hint="••••••••"
                                                    isRequired={false}
                                                    isDisabled={false}
                                                    inputStyle={textInputStyle.current.enabledInput}
                                                    inputValue={formState.passwordPair}
                                                    handleChange={handleChange}
                                                />
                                                <span className="flex justify-around items-center" onClick={handleTogglePair}>
                                                    <Icon className="absolute mr-10" icon={iconPair} size={25}/>
                                                </span>
                                            </div>
                                            <div className="response-error-message">
                                                {showFieldErrors(responseErrors.passwordPair)}
                                            </div>
                                        </div>

                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input 
                                                    id="privacypolicy" 
                                                    aria-describedby="privacypolicy" 
                                                    type="checkbox" 
                                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-400 focus:ring-3 focus:ring-primary-300 gray:bg-gray-700 gray:border-gray-600 gray:focus:ring-primary-600 gray:ring-offset-gray-800" 
                                                    required 
                                                />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="privacypolicy" className="font-light text-gray-500 gray:text-gray-300">
                                                    {t('login_register_page.accept_terms_the')}
                                                    <button
                                                        className="font-medium text-primary-600 hover:underline gray:text-primary-500"
                                                        onClick={openPrivacyPopup}
                                                        style={{ background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer' }}
                                                    >
                                                         {t('terms_and_conditions.privacy_policy')}
                                                    </button>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="response-error-message">
                                            {showFieldErrors(responseErrors.privacypolicy)}
                                        </div>
                                        <div>
                                            {/* Toast Container */}
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={5000}
                                                hideProgressBar={false}
                                                closeOnClick
                                                pauseOnHover
                                                draggable
                                                pauseOnFocusLoss
                                                theme="light"
                                            />
                                        </div>
                                        <div className="overflow-hidden sm:rounded-md" style={{ fontSize: '14px' }}>
                                                <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="resetPassword"/>
                                        </div>                     
                                    </form>
                                </div>
                            </div>
                        </div>
                    </TabContent>

                    <TabContent id="tab2" activeTab={activeTab}>
                        <div className="mt-[0px] mb-[100px] p-6 md:p-0 ">
                            <div className="grid grid-cols-26">
                                <div className="mt-0 md:col-span-2 md:mt-0">
                                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl gray:text-white">
                                         {t('manage_profile_page.update_farm_details')}
                                    </h2>   
                                    <form onSubmit={submitFarmRecords} autoComplete="off" noValidate>
                                            <div className="overflow-hidden sm:rounded-md">
                                                <div className="bg-white px-4 py-5 sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">
                                                        {/* Farm name */}
                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                        <InputLabel
                                                            inputId="farm_name"
                                                            inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                            labelText={t('manage_profile_page.farm_name')}
                                                        />
                                                        <TextInput
                                                            focus={true}
                                                            inputType="text"
                                                            inputName="farm_name"
                                                            inputId="farm_name"
                                                            hint={t('login_register_page.enter_farm_name')}
                                                            isAutoComplete="on"
                                                            isRequired={false}
                                                            isDisabled={false}
                                                            inputStyle={textInputStyle.current.enabledInput}
                                                            inputValue={formState.farm_name || ''}
                                                            handleChange={handleChange}
                                                        />
                                                        <div className="response-error-message">
                                                            {showFieldErrors(responseErrors.farm_name)}
                                                        </div>
                                                        </div>
                    
                    
                                                        {/* Farm Address */}
                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                        <InputLabel
                                                            inputId="farm_address"
                                                            inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                            labelText={t('manage_profile_page.farm_address')}
                                                        />
                                                        <TextInput
                                                            focus={false}
                                                            inputType="text"
                                                            inputName="farm_address"
                                                            inputId="farm_address"
                                                            hint={t('login_register_page.enter_farm_address')}
                                                            isAutoComplete="on"
                                                            isRequired={false}
                                                            isDisabled={false}
                                                            inputStyle={textInputStyle.current.enabledInput}
                                                            inputValue={formState.farm_address || ''}
                                                            handleChange={handleChange}
                                                        />
                                                        <div className="response-error-message">
                                                            {showFieldErrors(responseErrors.farm_address)}
                                                        </div>
                                                        </div>

                                                        {/* Farm Country and contact phone number */}
                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                            <InputLabel
                                                                inputId="country_name_and_phone_number"
                                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                                labelText={t('manage_profile_page.new_country_mobile')} 
                                                            />
                                                            <div>
                                                                <PhoneInput
                                                                    countrySelectProps={{ unicodeFlags: true }}
                                                                    defaultCountry="TZ"
                                                                    placeholder={t('login_register_page.enter_phone_number')} 
                                                                    value={phoneValue}
                                                                    onChange={handlePhoneChange}
                                                                />
                                                                {phoneError && <p className="text-red-500 text-sm font-medium">{phoneError}</p>}
                                                            </div>
                                                        </div>

                                                        {/* Farm Country */}
                                                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2"> */}
                                                            {/* <InputLabel
                                                                inputId="country_name"
                                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                                labelText={t('manage_profile_page.country_name')}
                                                            /> */}
                                                            {/* <TextInput
                                                                focus={false}
                                                                inputType="text"
                                                                inputName="country_name"
                                                                inputId="country_name"
                                                                hint={t('login_register_page.enter_country_name')}
                                                                isAutoComplete="on"
                                                                isRequired={false}
                                                                isDisabled={false}
                                                                inputStyle={textInputStyle.current.enabledInput}
                                                                inputValue={formState.country_name || ''}
                                                                handleChange={handleChange}
                                                            />  */}
                                                            {/* <div className="response-error-message">
                                                                {showFieldErrors(responseErrors.country_name)}
                                                            </div> */}
                                                        {/* </div>  */}

                                                        {/* contact phone number */}
                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                            <InputLabel
                                                                inputId="farm_contact_number"
                                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                                // labelText={t('manage_profile_page.phone_number_with_country_code')}
                                                                labelText={t('manage_profile_page.current_farm_number')} 
                                                            /> 
                                                            <TextInput
                                                                focus={false}
                                                                inputType="text"
                                                                inputName="farm_contact_number"
                                                                inputId="farm_contact_number"
                                                                hint={t('login_register_page.enter_phone_number')}
                                                                isAutoComplete="on"
                                                                isRequired={false}
                                                                isDisabled={true}
                                                                inputStyle={textInputStyle.current.enabledInput}
                                                                inputValue={formState.farm_contact_number || ''}
                                                                handleChange={handleChange}
                                                            />
                                                            {/* <div className="response-error-message">
                                                                {showFieldErrors(responseErrors.farm_contact_number)}
                                                            </div> */}
                                                        </div>  





                                                    </div>
                                                </div>
                                                <div className="overflow-hidden sm:rounded-md" style={{ fontSize: '14px' }}>
                                                    <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="updaterecord"/>
                                                </div>
                                            </div>
                                    </form>
                                    <h2 className="mb-1 text-sm leading-tight tracking-tight text-gray-500 md:text-2xl gray:text-white">
                                        {t('manage_profile_page.note_farm_owner_only')}
                                    </h2> 
                                </div>
                            </div>
                        </div>
                    </TabContent>

                    {/* Update Personal records tab */}
                    <TabContent id="tab3" activeTab={activeTab}>
                        <div className="mt-[0px] mb-[100px] p-6 md:p-0 ">
                            <div className="grid grid-cols-26">
                                <div className="mt-0 md:col-span-2 md:mt-0">
                                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl gray:text-white">
                                        {t('manage_profile_page.update_personal_details')}
                                    </h2>                          
                                    <form onSubmit={submitPersonalRecords} autoComplete="off" noValidate>
                                            <div className="overflow-hidden sm:rounded-md">
                                                <div className="bg-white px-4 py-5 sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">

                                                    {/* First name */}
                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                        <InputLabel
                                                        inputId="first_name"
                                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                        labelText={t('manage_users_page.first_name')}
                                                        />
                                                        <TextInput
                                                            focus={true}
                                                            inputType="text"
                                                            inputName="first_name"
                                                            inputId="first_name"
                                                            hint={t('manage_users_page.enter_first_name')}
                                                            isAutoComplete="on"
                                                            isRequired={false}
                                                            isDisabled={false}
                                                            inputStyle={textInputStyle.current.enabledInput}
                                                            inputValue={formState.first_name || user?.firstName || ''}
                                                            handleChange={handleChange}
                                                        />
                                                        <div className="response-error-message">
                                                        {showFieldErrors(responseErrors.first_name)}
                                                        </div>
                                                    </div> 


                                                    {/* Middle name */}
                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                        <InputLabel
                                                        inputId="middle_name"
                                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                        labelText={t('manage_users_page.middle_name')}
                                                        />
                                                        <TextInput
                                                            focus={false}
                                                            inputType="text"
                                                            inputName="middle_name"
                                                            inputId="middle_name"
                                                            hint={t('manage_users_page.enter_middle_name')}
                                                            isAutoComplete="on"
                                                            isRequired={false}
                                                            isDisabled={false}
                                                            inputStyle={textInputStyle.current.enabledInput}
                                                            inputValue={formState.middle_name}
                                                            handleChange={handleChange}
                                                        />
                                                        <div className="response-error-message">
                                                        {showFieldErrors(responseErrors.middle_name)}
                                                        </div> 
                                                    </div>

                                                    {/* Last name  */}
                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                        <InputLabel
                                                        inputId="last_name"
                                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                        labelText={t('manage_users_page.last_name')}
                                                        />
                                                        <TextInput
                                                            focus={false}
                                                            inputType="text"
                                                            inputName="last_name"
                                                            inputId="last_name"
                                                            hint={t('manage_users_page.enter_last_name')}
                                                            isAutoComplete="on"
                                                            isRequired={false}
                                                            isDisabled={false}
                                                            inputStyle={textInputStyle.current.enabledInput}
                                                            inputValue={formState.last_name || user?.lastName || ''}
                                                            handleChange={handleChange}
                                                        />
                                                        <div className="response-error-message">
                                                        {showFieldErrors(responseErrors.last_name)}
                                                        </div>
                                                    </div>                       
                                                    

                                                    {/* Personal Country and contact phone number */}
                                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                        <InputLabel
                                                            inputId="country_name_and_phone_number"
                                                            inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                            labelText={t('manage_profile_page.new_mobile_number')} 
                                                        />
                                                        <div>
                                                            <PhoneInput
                                                                countrySelectProps={{ unicodeFlags: true }}
                                                                defaultCountry="TZ"
                                                                placeholder={t('login_register_page.enter_phone_number')} 
                                                                value={phoneValue}
                                                                onChange={handlePhoneChange}
                                                            />
                                                            {phoneError && <p className="text-red-500 text-sm font-medium">{phoneError}</p>}
                                                        </div>
                                                        <div className="response-error-message">
                                                                {showFieldErrors(responseErrors.personal_contact_number)}
                                                        </div>
                                                    </div>

                                                        {/* Personal contact phone number */}
                                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                            <InputLabel
                                                                inputId="personal_contact_number"
                                                                inputStyle="block text-sm font-medium text-gray-700 text-left"
                                                                // labelText={t('manage_profile_page.phone_number_with_country_code')}
                                                                labelText={t('manage_profile_page.current_personal_number')} 
                                                            /> 
                                                            <TextInput
                                                                focus={false}
                                                                inputType="text"
                                                                inputName="personal_contact_number"
                                                                inputId="personal_contact_number"
                                                                hint={t('login_register_page.enter_phone_number')}
                                                                isAutoComplete="on"
                                                                isRequired={false}
                                                                isDisabled={true}
                                                                inputStyle={textInputStyle.current.enabledInput}
                                                                inputValue={formState.person_contact_number || ''}
                                                                handleChange={handleChange}
                                                            />
                                                        </div>  
                                                </div> 
                                            </div>


                                            <div className="overflow-hidden sm:rounded-md" style={{ fontSize: '14px' }}>
                                                <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="updaterecord"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </TabContent>

                  </div>
              </div>
              
            </div>
            
          </div>
        </center>
      );

}
export {ManageProfileForm}