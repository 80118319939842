import { MenuItem, DummyMenuItem, BottomNavigation } from "../components";
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from "../contexts";
import { useLayoutEffect } from "react";

import {
    SearchIcon,
    StopIcon,
    AnalyzeIcon,
    ReportDataIcon,
    ReportLineChartIcon,
    ReportTextIcon,
    ReportBarChartIcon,
    ActivityIcon,
    EventsAltIcon
 } from "../icons";

const ReportsMenu = () => {
    const { user } = useUser();  // Get currently loggedIn user
    const { setEnvState } = useEnv(); // Get Environment context Setter

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_reports'), backPath: "", homePath: "/fe"}));
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: "/fe"}));
        }
       // eslint-disable-next-line
     }, []);

    // for language translation
    const { t } = useTranslation();

    return(
        // <div className="mt-[140px] mb-[100px] p-4 md:p-0 ">
        <div className="mt-[100px] mb-0 p-2 md:p-0">
            <div className="grid grid-cols-6">
                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div className="col-span-4 flex flex-col gap-y-4">
                    {/** Menu Item row */}
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/search" itemTitle={t('navigation_element.nav_item_title_search')}>
                                <SearchIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/cattle" itemTitle={t('navigation_element.nav_item_title_cattle')}>
                                <AnalyzeIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/milkings" itemTitle={t('navigation_element.nav_item_title_milking')}>
                                <ReportDataIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/pregnancy" itemTitle={t('navigation_element.nav_item_title_pregnancy')}>
                                <ReportLineChartIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/weighings" itemTitle={t('navigation_element.nav_item_title_weighing')}>
                                <ReportTextIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/events" itemTitle={t('navigation_element.nav_item_title_events')}>
                                <ActivityIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/reports/visits" itemTitle={t('navigation_element.nav_item_title_visitors')}>
                                <EventsAltIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/search" itemTitle={t('buttons_navigation.button_nav_trading')}>
                                <ReportBarChartIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                    </div>
                </div>


                
                {/** Left spacer */}
                <div className="col-span-1"></div>
                {/* Bottom Navigation */}
                <BottomNavigation />
            </div>
            <div className="flex flex-col justify-center items-center mt-2 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                {/* <span className="whitespace-nowrap">{t('home_page.courtesy')}</span> */}
                <span className="whitespace-nowrap">{t('home_page.web')}</span>
                <span className="whitespace-nowrap">{t('home_page.email')}</span>
                <span className="whitespace-nowrap">{t('home_page.copyright')}</span>
            </div>
        </div>
    );
}

export { ReportsMenu };