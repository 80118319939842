import React, { createContext, useState, useContext } from "react";
import { ConsoleLogEvent } from "./ConsoleLogEvent";

const GoogleIdentityContext = createContext();

const GoogleIdentityServices = () => {
  return new Promise((resolve, reject) => {
    if (window.google?.accounts?.id) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = () => reject(new Error("Failed to load Google Identity Services"));
    document.head.appendChild(script);
  });
};

const GoogleIdentityProvider = ({ children }) => {
  const [isPromptActive, setIsPromptActive] = useState(false);

  const showPrompt = () => {
    if (isPromptActive) return;

    setIsPromptActive(true);
    window.google.accounts.id.prompt((notification) => {
      setIsPromptActive(false);

      switch (notification.type) {
        case "dismissed":
          ConsoleLogEvent('warn', 'User dismissed the sign-in prompt.');
          break;
        case "skipped":
          ConsoleLogEvent('info', 'User skipped the sign-in prompt.');
          break;
        case "displayed":
          ConsoleLogEvent('info', 'Sign-in prompt displayed successfully.');
          break;
        default:
          ConsoleLogEvent('warn', 'Unhandled prompt notification type:', notification.type || "undefined");
      }
    });
  };

  return (
    <GoogleIdentityContext.Provider value={{ showPrompt }}>
      {children}
    </GoogleIdentityContext.Provider>
  );
};

const generateRandomPassword = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
};

  const isFedCMSupported = () => {
    return (
      window.google &&
      window.google.accounts &&
      typeof window.google.accounts.id.prompt === 'function'
    );
  };

const useGoogleIdentity = () => useContext(GoogleIdentityContext);

export { GoogleIdentityServices, GoogleIdentityProvider, useGoogleIdentity, isFedCMSupported, generateRandomPassword };
