import React, { useState, useEffect, useMemo } from 'react';
import { QUERY_FILTER_LISTED_CATTLE } from '../constants';
import { getResource } from '../services/farmApi';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useEnv } from '../contexts';
import { useHandleSubmit } from '../hooks';
import { Link } from 'react-router-dom';
import { 
    getCategoryMapping, 
    getBreedMapping, 
    getCurrencyMapping, 
    getVaccinationHistoryMapping 
} from '../components/Mappings';

import { useLocation } from 'react-router-dom';
import { ConsoleLogEvent } from '../utils';

import { BottomNavigation } from '../components';

const SaleBrowseForm = () => {

    const { t } = useTranslation(); // for language translation
    const categoryMapping = getCategoryMapping(t);
    const breedMapping = getBreedMapping(t);
    const currencyMapping = getCurrencyMapping(t);
    const vaccinationHistoryMapping = getVaccinationHistoryMapping(t);

    const { user } = useUser(); // Get currently logged-in user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const [createNotice, setCreateNotice] = useState({ showNotice: false, succ: false, msg: "" });
    const [reportData, setReportData] = useState([]);
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState(null); // Error message state

    const location = useLocation();
    const countryNameCode = location.state?.country; // Access the passed country value

    // Fetch data and update reportData
    useEffect(() => {
        // localStorage.removeItem('allproducts');
        getListProduct();
    }, [envState.online]);

    const getListProduct = async () => {
        setLoading(true);  // Start loading

        const reportQuery = "/?filter=" + QUERY_FILTER_LISTED_CATTLE;

        try {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE + reportQuery,
                setCreateNotice,
                null,
                setReportData, // This will update reportData
                null,
                false
            );
            setErrorMessage(null); // Clear any previous errors
        } catch (error) {
            // console.error('Error fetching report data:', error);
            setErrorMessage(t('error_message.failed_to_fetch'));
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Update localStorage whenever reportData changes
    useEffect(() => {
        ConsoleLogEvent('info', 'Sales items received: ', reportData);
    }, [reportData]);  // This effect runs whenever reportData changes

    const products = Array.isArray(reportData.returned_records) ? reportData.returned_records : [];
    // const filteredProducts = useMemo(() => {
    //     const searchLower = searchTerm.toLowerCase();
    //     return products.filter((product) => {
    //         return (
    //             product.category?.toLowerCase().includes(searchLower) ||
    //             product.breed?.toLowerCase().includes(searchLower) ||
    //             product.region?.toLowerCase().includes(searchLower) ||
    //             product.currency?.toLowerCase().includes(searchLower) ||
    //             product.price?.toString().toLowerCase().includes(searchLower)
    //         ) && (!selectedCategory || product.category === selectedCategory);
    //     });
    // }, [products, searchTerm, selectedCategory]);
    
    const filteredProducts = useMemo(() => {
        const searchLower = searchTerm.toLowerCase();
        return products.filter((product) => {
            const matchesSearch = 
                product.category?.toLowerCase().includes(searchLower) ||
                product.breed?.toLowerCase().includes(searchLower) ||
                product.region?.toLowerCase().includes(searchLower) ||
                product.currency?.toLowerCase().includes(searchLower) ||
                product.price?.toString().toLowerCase().includes(searchLower);
            
            const matchesCategory = !selectedCategory || product.category === selectedCategory;
    
            // Only filter by country if countryNameCode.value is defined, non-null, and non-empty
            const matchesCountry = !countryNameCode?.value || product.country_code === countryNameCode.value;
    
            return matchesSearch && matchesCategory && matchesCountry;
        });
    }, [products, searchTerm, selectedCategory, countryNameCode]);
    
    // Memoize filtered products and categories to avoid recalculating them on every render unless necessary.
    const categories = useMemo(() => {
        return [...new Set(products.map((product) => product.category))];
    }, [products]);

    // Reusable function for handling JSX handling of images and videos or other media types.
    const MediaDisplay = ({ media }) => {
        if (media.startsWith('data:image')) {
            return <img src={media} className="card-img-top w-full h-auto" alt="Media" style={{ objectFit: 'contain' }} />;
        } else if (media.startsWith('data:video')) {
            return <video src={media} className="card-video-top w-full h-auto" controls style={{ objectFit: 'contain' }} />;
        }
        return null;
    };

    const handleViewDetails = (product) => {
        const existingProducts = JSON.parse(localStorage.getItem(`products_${user.id}`)) || [];
    
        // Remove image_base before storing
        const { image_base, ...productWithoutImageBase } = product;
    
        if (Array.isArray(existingProducts)) {
            localStorage.setItem(`products_${user.id}`, JSON.stringify([...existingProducts, productWithoutImageBase]));
            ConsoleLogEvent('info', 'Stored products:', JSON.parse(localStorage.getItem(`products_${user.id}`)));
        } else {
            localStorage.setItem(`products_${user.id}`, JSON.stringify([productWithoutImageBase]));
        }
    };
    

    return (
        <div className='container mt-2'>
            {/* <div className="mb-2"></div> */}
            <div>{t('market_place_page.for_sale_tittle')} </div>
            {loading && <div>{t('validation_messages.loading_products')}</div>}
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <div className="flex flex-wrap justify-start mb-2">
                {/* 'All' Button */}
                <button
                    type="button"
                    className={`min-w-[60px] h-[40px] mb-2 mr-2 inline-flex justify-center px-2 py-2 rounded ${selectedCategory === null ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                    onClick={() => setSelectedCategory(null)}
                >
                    {t('market_place_page.all_category')}
                </button>
                {/* Category Buttons */}
                {categories.map((category) => (
                    <button
                        key={category}
                        type="button"
                        className={`min-w-[40px] h-[40px] mb-2 mr-2 inline-flex justify-center px-2 py-2 rounded ${selectedCategory === category ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                        onClick={() => setSelectedCategory(category)}
                    >
                        {categoryMapping[category] || category}
                    </button>
                ))}
            </div>

            {/* Search Field */}
            <input
                type='text'
                placeholder='Search for cattle...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className='form-control mb-3 w-full'
            />

            <div className='row'>
                {filteredProducts.length === 0 && (
                    <div className="w-full text-center">
                        {t('market_place_page.no_cattle_for_sale_listed')}
                    </div>
                )}
                {filteredProducts.map((product) => (
                    <div key={product.list_id} className='col-md-3 mb-4'>
                        {/* <Link to={`product-detail/${product.list_id}`}> */}
                        <Link 
                            to={`product-detail/${product.list_id}`} 
                            onClick={(e) => {
                                // Call handleViewDetails to store product data in localStorage
                                handleViewDetails(product);
                            }}
                        >
                            <div className='card' style={{ width: '100%' }}>
                                {/* Image for the sale item */}
                                {/* <MediaDisplay media={product.image_base} altText={product.breed || 'Media'} /> */}
                                <div className="media-container">
                                    <MediaDisplay media={product.image_base} altText={product.breed || 'Media'} />
                                </div>
                                <div className='card-body'>
                                    
                                        <h5 className='card-title' style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                                            {t('market_place_page.manage_face_cattle_side_category')}: {categoryMapping[product.category] || 'Unknown Category'}
                                        </h5>
                                        <p className='card-title' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                            {t('market_place_page.manage_face_cattle_side_breed')}: {breedMapping[product.breed] || 'Unknown Breed'}
                                        </p>
                                        <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                            <span style={{ fontWeight: 'bold' }}>{t('market_place_page.view_details_face_price')}:</span> 
                                                {currencyMapping[product.currency] || product.currency} {Number(product.price).toLocaleString('en-US', {
                                                                                    minimumFractionDigits: 0,
                                                                                    maximumFractionDigits: 0
                                                                                })}
                                        </p>
                                        <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                            <span style={{ fontWeight: 'bold' }}>{t('market_place_page.manage_face_item_side_item_location')}:</span> {product.region}
                                        </p>
                                        <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                            <span style={{ fontWeight: 'bold' }}>{t('market_place_page.advertise_face_vaccination_record')}:</span> {vaccinationHistoryMapping[product.vaccination_record]}
                                        </p>
                                        <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                            <span style={{ fontWeight: 'bold' }}>{t('market_place_page.list_number')}:</span> {product.list_id}
                                        </p>
                                        <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                            <span style={{ fontWeight: 'bold' }}>{t('manage_profile_page.country')}:</span> {product.country_name}
                                        </p>
                                        <button className='btn btn-primary'
                                            onClick={(e) => {
                                                handleViewDetails(product);
                                            }}
                                        >
                                            {t('market_place_page.advertise_face_view_details')}
                                        </button>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <ToastContainer />
            {/* Bottom Navigation */}
            {/* <BottomNavigation /> */}
        </div>
    );

    
};

export { SaleBrowseForm };
