import React, { useLayoutEffect, useState, useEffect } from "react";
import { handleMenuItemClick } from "./HamburgerMenuItem";
import { BottomNavigation, MenuItem} from "./index";
import { getQueueLength } from "../services/queue";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEnv } from "../contexts";
import { toast } from "react-toastify";

import { 
    ArrowPathRoundedSmallerIcon, 
    ShareIcon,
    FeedbackIcon,
    AboutUsIcon,
    DisclaimerIcon,
    TermsAndConditionIcon,
    PrivatePolicy,
    ManualIcon,
    DairyFarmingIcon,
} from "../icons";

const MoreMenu = () => {
    const { setEnvState } = useEnv(); // Get Environment context Setter
    const { t, i18n } = useTranslation(); // for language translation
    const navigate = useNavigate();

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('buttons_navigation.button_bottom_nav_more'), backPath: "", homePath: "/fe"})); 
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: "/fe"}));
        }
        // eslint-disable-next-line
    }, []);

    // Queue count state
    const [queueCount, setQueueCount] = useState(getQueueLength() ?? 0);

    useLayoutEffect(() => {
    const handleQueueChange = () => {
        setQueueCount(() => getQueueLength());
    };
    document.addEventListener("queueing", handleQueueChange, true);
    return document.removeEventListener("queueing", handleQueueChange);
    }, []);

    useEffect(() => {
        // Listen for changes in queueCount
        const handleQueueChange = () => {
            setQueueCount(getQueueLength());
        };
        document.addEventListener("queueing", handleQueueChange, true);
        return () => document.removeEventListener("queueing", handleQueueChange);
    }, [queueCount]); // Run the effect when queueCount changes

    const showToast = (message, type) => {
    toast[type](message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    };

    return(
        // <div className="mt-[140px] mb-[100px] p-2 md:p-0 ">
        <div className="mt-[100px] mb-0 p-2 md:p-0">
            <div className="grid grid-cols-6">
                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div className="col-span-4 flex flex-col gap-y-4">
                        

                    {/** Share and Queue data buttons */}
                    <div className="flex justify-center items-center gap-x-3">
                        <button 
                               onClick={() => handleMenuItemClick("/share", null, t, null, showToast)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.share")}>
                                <ShareIcon />
                            </MenuItem>
                        </button> 

                        <button 
                               onClick={() => handleMenuItemClick('/clearQueue', navigate, t, queueCount, showToast)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.upload_queue_data")}>
                                <ArrowPathRoundedSmallerIcon />
                            </MenuItem>
                        </button>

                    </div>

                    {/** About Us and Feedback buttons */}
                    <div className="flex justify-center items-center gap-x-3">
                        <button 
                               onClick={() => handleMenuItemClick("/aboutUs", navigate, t)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.about_us")}>
                                <AboutUsIcon />
                            </MenuItem>
                        </button> 

                        <button 
                               onClick={() => handleMenuItemClick("/feedback", navigate, t)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.feedback")}>
                                <FeedbackIcon />
                            </MenuItem>
                        </button> 
                    </div>


                    {/**  Manual and Dairy Farming information */}
                    <div className="flex justify-center items-center gap-x-3">
                        <button 
                               onClick={() => handleMenuItemClick("/appmanual", navigate, t)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.manual")}>
                                <ManualIcon />
                            </MenuItem>
                        </button> 


                        <button 
                               onClick={() => handleMenuItemClick("/dairyfaming", navigate, t, null, null, i18n)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.dairyfarming")}>
                                <DairyFarmingIcon />
                            </MenuItem>
                        </button>
                    </div>

                    {/** Terms And Conditions and Privacy buttons */}
                    <div className="flex justify-center items-center gap-x-3">
                        <button 
                               onClick={() => handleMenuItemClick("/tandcs", navigate, t)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.tandcs")}>
                                <TermsAndConditionIcon />
                            </MenuItem>
                        </button> 


                        <button 
                               onClick={() => handleMenuItemClick("/privacy", navigate, t, null, null, i18n)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("terms_and_conditions.privacy_policy")}>
                                <PrivatePolicy />
                            </MenuItem>
                        </button>
                    </div>   

                    {/** FAQ and Disclaimer buttons */}
                    <div className="flex justify-center items-center gap-x-3">
                        <button 
                               onClick={() => handleMenuItemClick("/faq", navigate, t)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.faq")}>
                                <TermsAndConditionIcon />
                            </MenuItem>
                        </button> 

                        <button 
                               onClick={() => handleMenuItemClick("/disclaimer", navigate, t)}
                            >
                            <MenuItem urlSegment="" itemTitle={t("hamburger_messages.disclaimer")}>
                                <DisclaimerIcon />
                            </MenuItem>
                        </button>
                    </div> 






                </div>
            </div>
            {/** Left spacer */}
            <div className="col-span-1"></div>
            {/* Bottom Navigation */}
            <BottomNavigation />
            <div className="flex flex-col justify-center items-center mt-2 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                {/* <span className="whitespace-nowrap">{t('home_page.courtesy')}</span> */}
                <span className="whitespace-nowrap">{t('home_page.web')}</span>
                <span className="whitespace-nowrap">{t('home_page.email')}</span>
                <span className="whitespace-nowrap">{t('home_page.copyright')}</span>
            </div>
        </div>
    );

}

export { MoreMenu };