import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { createResource, getResource } from '../services/farmApi';
import { Footer, EditDelTableDataView, BottomNavigation } from '../components';
import TabNavItem from "../components/forms/TabNavItem";
import TabContent from "../components/forms/TabContent";
import { toast, ToastContainer } from 'react-toastify';
import { useEvents, useHandleSubmit } from '../hooks';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import "./reports/reportStyle.css";
import "../tabsview.css";


import { 
    getCategoryMapping, 
    getBreedMapping, 
    getCurrencyMapping, 
    getVaccinationHistoryMapping,
    getCategoryMappingRequests
} from '../components/Mappings';

import { 
    TRANS_TYPE_REOPEN_CATTLE_4_SALE, 
    TRANS_TYPE_REMOVE_CATTLE_4_SALE, 
    TRANS_TYPE_CLOSE_CATTLE_4_SALE,
    TRANS_TYPE_REOPEN_ITEM_NEEDED,
    TRANS_TYPE_REMOVE_ITEM_NEEDED,
    TRANS_TYPE_CLOSE_ITEM_NEEDED,
    QUERY_FILTER_MANAGE_ITEMS,
    QUERY_FILTER_MANAGE_CATTLE

 } from '../constants';


const CloseDealForm = () => {
    // for language translation
    const { t } = useTranslation();
    // Use the mapping functions with `t` for translation
    const categoryMapping = getCategoryMapping(t);
    const breedMapping = getBreedMapping(t);
    const currencyMapping = getCurrencyMapping(t);
    const vaccinationHistoryMapping = getVaccinationHistoryMapping(t);
    const categoryMappingRequests = getCategoryMappingRequests(t);

    const { user } = useUser();  // Get currently loggedIn user

    const showToast = (message, type) => {
        toast[type](message, {
          position: "top-center", 
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    };

    const [tableSettings, setTableSettings] = useState({
        idUsage: 1,
        useSerial: true,
    });

    const initNotice = useRef({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,
        ver: null,
    });

    // const [createNotice, setCreateNotice] = useState(initNotice.current);
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    const [selectedId, setSelectedId] = useState(0);
    const [footerType, setFooterType] = useState("nav");
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler
    const [submitTriggered, setSubmitTriggered] = useState(false);
    const [eventData, setEventData] = useState(null);  // To store the event
    const [initFormState, setInitFormState] = useState(null);
    const resetFormState = () => { setFormState(initFormState) }
    const [responseErrors, setResponseErrors] = useState({});

    const [submitItemTriggered, setSubmitItemTriggered] = useState(false);
    const {envState, setEnvState } = useEnv(); // Get Environment context
    const [reportData, setReportData] = useState([]);
    const [reportCattleData, setCattleReportData] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState(null); // Error message state
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler

    const [activeTab, setActiveTab] = useState("tab1");


    //-------------- start cattle handling ------------------------

        useEffect(() => {
            getListCattleProduct();
        }, [envState.online]);

        const getListCattleProduct = async () => {
            setLoading(true);  // Start loading

            const reportQueryItem = "/?filter=" + QUERY_FILTER_MANAGE_CATTLE;

            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_MARKET_PLACE + reportQueryItem,
                    setCreateNotice,
                    null,
                    setCattleReportData, // This will update reportData
                    null,
                    false
                );
                setErrorMessage(null); // Clear any previous errors
            } catch (error) {
                // console.error('Error fetching report data:', error);
                setErrorMessage(t('market_place_page.failed_fetch_data'));
            } finally {
                setLoading(false); // Stop loading
            }
        };

        // Update localStorage whenever reportData changes
        useEffect(() => {
            // console.log('received data: ', reportCattleData);
        }, [reportCattleData]);  // This effect runs whenever reportData changes 


        useEffect(() => {
            const initFormState = {
                trans_type: '', // listing C: Close R: Re-advertise
                list_id: '',
                user_id: user.id,  // Assuming `user.id` is defined
            };
            setInitFormState(initFormState);
            setFormState(initFormState); // Set formState when initFormState is set
        }, []);

        const [formState, setFormState] = useState({
            trans_type: '', // listing C: Close R: Re-advertise
            list_id: '',
            seller_id: '',
            user_id: user.id,  // Assuming `user.id` is defined
        }); 

        useEffect(() => {
            setTableSettings({ idUsage: 1, useSerial: true });
            // Fetch additional data if needed
        }, []);

        useLayoutEffect(() => {
            setFooterType("closeOpen");
        }, [createNotice.succ]);

        const handleOnSelectId = (inputKey, inputValue) => {
            setSelectedId(inputValue);
        };

        // Re-advertise, put it backing to sales list
        const handleReopenSale = async (e) => {
            e.preventDefault();
            // O: 0 command, R: 2 command C: 1 command for Open (0), Remove (2) and Close (1) options
            if (selectedId) {

                setFormState((prevState) => ({
                    ...prevState,
                    trans_type: TRANS_TYPE_REOPEN_CATTLE_4_SALE,
                    seller_id: user.email,
                    list_id: selectedId,       // Set file name in form state
                }));

                setEventData(e);  // Store the event
                // Set submit flag to true, indicating form should be submitted after formState is updated
                setSubmitTriggered(true);
                // submitForm(e);

            } else {
                showToast(t("validation_messages.select_record_first"), "error");  
            }
        };

        // Remove previously listed as a sale
        const handleRemoveSale = async (e) => {
            e.preventDefault();
            // O: 0 command, R: command C: command for Open (0), Remove (2) and Close (1) options
            if (selectedId) {

                setFormState((prevState) => ({
                    ...prevState,
                    trans_type: TRANS_TYPE_REMOVE_CATTLE_4_SALE,
                    seller_id: user.email,
                    list_id: selectedId,       // Set file name in form state
                }));

                setEventData(e);  // Store the event
                // Set submit flag to true, indicating form should be submitted after formState is updated
                setSubmitTriggered(true);
                // submitForm(e);

            } else {
                showToast(t("validation_messages.select_record_first"), "error");  
            }
        };

        // Close or remove from the sales list
        const handleCloseSale = async (e) => {
            e.preventDefault();
            // O: 0 command, R: command C: command for Open (0), Remove (2) and Close (1) options
            if (selectedId) {

                setFormState((prevState) => ({
                    ...prevState,
                    trans_type: TRANS_TYPE_CLOSE_CATTLE_4_SALE,
                    seller_id: user.email,
                    list_id: selectedId,       // Set file name in form state
                }));

                setEventData(e);  // Store the event
                // Set submit flag to true, indicating form should be submitted after formState is updated
                setSubmitTriggered(true);
                // submitForm(e);

            } else {
                showToast(t("validation_messages.select_record_first"), "error");  
            }
        };

        // useEffect that watches formState and submitTriggered
        useEffect(() => {
            // If submitTriggered is true and formState has been updated, submit the form
            if (submitTriggered && eventData) {
                submitForm(eventData);  // Pass the stored eventData to submitForm
                setSubmitTriggered(false);  // Reset the trigger flag after submission
                setEventData(null);  // Clear the stored event
            }
            
        }, [formState]);

        // Submit form with necessary parameters
        const submitForm = (e) => {
            e.preventDefault(); // Prevent form from reloading the page

            // clear previous errors
            setResponseErrors({});
            setCreateNotice('');

            // console.log('selected ID: ', selectedId);

            // Submit form using handleSubmit, passing formState and necessary parameters
            handleSubmit(
                e,
                formState,  // pass formState with base64 and media details
                createResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE,
                setResponseErrors,
                setCreateNotice,
                resetFormState,
                publish,
                t
            );

            // Display success message and reset form
            toast.success(t("market_place_page.item_for_sale_added"));
        };


        const formattedData = (reportCattleData.returned_records || []).map(item => {
            const formattedDate = new Date(item.created_at).toISOString().split('T')[0];
            
            let listStatusText;
            if (item.list_status === "0") {
                listStatusText = t('market_place_page.on_sale');
            } else if (item.list_status === "1") {
                listStatusText = t('market_place_page.sold');
            } else if (item.list_status === "2") {
                listStatusText = t('market_place_page.removed');
            } else {
                listStatusText = t('market_place_page.unknown');  // For any other unexpected statuses
            }
        
            return {
                id: item.list_id,  // Use list_id as the id
                list_id: item.list_id,
                category: categoryMapping[item.category] || item.category,
                breed: breedMapping[item.breed] || item.breed,
                created_at: formattedDate,
                price: currencyMapping[item.currency] + ' ' + Number(item.price).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }),
                list_status: listStatusText,  
            };
        });

        const handleChangeCattle = (inputKey, inputValue) => {
            
            setInitFormState(initFormState);
            setFormState(initFormState); 
            setResponseErrors({});
        }

    //----------- end cattle handling -------------------------





    //----------- start items handling ------------------------
        useEffect(() => {
            getListProduct();
        }, [envState.online]);

        const getListProduct = async () => {
            setLoading(true);  // Start loading

            const reportQueryItem = "/?filter=" + QUERY_FILTER_MANAGE_ITEMS;

            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_MARKET_PLACE + reportQueryItem,
                    setCreateNotice,
                    null,
                    setReportData, // This will update reportData
                    null,
                    false
                );
                setErrorMessage(null); // Clear any previous errors
            } catch (error) {
                // console.error('Error fetching report data:', error);
                setErrorMessage(t('market_place_page.failed_fetch_data'));
            } finally {
                setLoading(false); // Stop loading
            }
        };

        // Update localStorage whenever reportData changes
        useEffect(() => {
        }, [reportData]);  // This effect runs whenever reportData changes   
        
        const formattedItemData = (reportData.returned_records || []).map(item => {
            const formattedItemDate = new Date(item.created_at).toISOString().split('T')[0];
        
            let listStatusText;
            if (item.list_status === '0') listStatusText = t('market_place_page.posted');
            else if (item.list_status === '1') listStatusText = t('market_place_page.found');
            else if (item.list_status === '2') listStatusText = t('market_place_page.not_found');
            else listStatusText = t('market_place_page.unknown');
        
            return {
                id: item.list_id,
                list_id: item.list_id,
                category: categoryMappingRequests[item.category] || item.category,
                description: item.description.length > 15 
                            ? `${item.description.slice(0, 15)}...` 
                            : item.description,
                region: item.region,
                created_at: formattedItemDate, 
                list_status: listStatusText,  
            };
        });


        const [formStateItem, setFormStateItem] = useState({
            trans_type: '',
            list_id: '',
            seller_id: '',
            user_id: user.id,
        });

        const handleOnItemSelectId = (inputKey, inputValue) => {
            setSelectedId(inputValue);
        };

        // Re-advertise, put it backing to sales list
        const handleReopenItemNeeded = async (e) => {
            e.preventDefault();
            // O: 0 command, R: 2 command C: 1 command for Open (0), Remove (2) and Close (1) options
            if (selectedId) {

                setFormStateItem((prevState) => ({
                    ...prevState,
                    trans_type: TRANS_TYPE_REOPEN_ITEM_NEEDED,
                    seller_id: user.email,
                    list_id: selectedId,       // Set file name in form state
                }));

                setEventData(e);  // Store the event
                // Set submit flag to true, indicating form should be submitted after formState is updated
                setSubmitItemTriggered(true);

            } else {
                showToast(t("validation_messages.select_record_first"), "error");  
            }
        };
        
        // Remove previously listed as a sale
        const handleRemoveItemNeeded = async (e) => {
            e.preventDefault();
            // O: 0 command, R: command C: command for Open (0), Remove (2) and Close (1) options
            if (selectedId) {

                setFormStateItem((prevState) => ({
                    ...prevState,
                    trans_type: TRANS_TYPE_REMOVE_ITEM_NEEDED,
                    seller_id: user.email,
                    list_id: selectedId,       // Set file name in form state
                }));

                setEventData(e);  // Store the event
                // Set submit flag to true, indicating form should be submitted after formState is updated
                setSubmitItemTriggered(true);

            } else {
                showToast(t("validation_messages.select_record_first"), "error");  
            }
        };

        // Close or remove from the sales list
        const handleCloseItemNeeded = async (e) => {
            e.preventDefault();
            // O: 0 command, R: command C: command for Open (0), Remove (2) and Close (1) options
            if (selectedId) {

                setFormStateItem((prevState) => ({
                    ...prevState,
                    trans_type: TRANS_TYPE_CLOSE_ITEM_NEEDED,
                    seller_id: user.email,
                    list_id: selectedId,       // Set file name in form state
                }));

                setEventData(e);  // Store the event
                // Set submit flag to true, indicating form should be submitted after formState is updated
                setSubmitItemTriggered(true);

            } else {
                showToast(t("validation_messages.select_record_first"), "error");  
            }
        };


        const handleChangeItems = (inputKey, inputValue) => {

            // setInitFormState(initFormState);
            resetFormState(); 
            setResponseErrors({});
        }

       // useEffect that watches formState and submitTriggered
       useEffect(() => {
            if (submitItemTriggered && formStateItem.list_id) {
                submitItemForm(eventData);
                setSubmitItemTriggered(false);
                setEventData(null);
            }
        }, [formStateItem, submitItemTriggered]);

        // Submit form with necessary parameters
        const submitItemForm = (e) => {
            e.preventDefault(); // Prevent form from reloading the page

            // Submit form using handleSubmit, passing formState and necessary parameters
            handleSubmit(
                e,
                formStateItem,  // pass formState with base64 and media details
                createResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE,
                setResponseErrors,
                setCreateNotice,
                resetFormStateItem,
                publish,
                t
            );

            // Display success message and reset form
            toast.success(t('market_place_page.item_for_sale_added'));
        }; 

        const resetFormStateItem = () => {
            setFormStateItem({
                trans_type: '',
                list_id: '',
                seller_id: '',
                user_id: user.id,
            });
        };

    //----------- end items handling ---------------------------

// ------------------ start ------------------------------
return (
    <div className="overflow-hidden sm:rounded-md">
        <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-1 md:col-span-2 md:mt-0">
                <ul className="nav">
                        <TabNavItem 
                            title={t('market_place_page.mapping_cattle')} 
                            id="tab1"
                            activeTab={activeTab} 
                            setActiveTab={setActiveTab}
                            handleChange={() => handleChangeCattle("tab1", "tab1")}
                        />

                        <TabNavItem 
                            title={t('market_place_page.item_header')} 
                            id="tab2" 
                            activeTab={activeTab} 
                            setActiveTab={setActiveTab}
                            handleChange={() => handleChangeItems("tab2", "tab2")}
                        />
                </ul>
                <div className="outlet">
                    {/* Cattle tab */}
                    <TabContent id="tab1" activeTab={activeTab}>
                        <div className="text-sm"> 
                            {formattedData && formattedData.length > 0 ? (
                                <form autoComplete="off" className="w-full">
                                    <div className="overflow-hidden sm:rounded-md">
                                        <div className="col-span-6 lg:col-span-3">
                                            <div className="w-full flex justify-center">
                                                <EditDelTableDataView
                                                    tableData={formattedData}
                                                    idUsage={0}
                                                    useSerial={tableSettings.useSerial}
                                                    mapHash={{
                                                        "#": "#",
                                                        list_id: t('market_place_page.sale_id'),
                                                        category: t('market_place_page.manage_face_cattle_side_category'),
                                                        breed: t('market_place_page.view_details_face_breed'),
                                                        created_at:  t('market_place_page.listed'),
                                                        price:   t('market_place_page.view_details_face_price'),
                                                        list_status: t('market_place_page.manage_face_cattle_side_status'),
                                                    }}
                                                    selectedId={selectedId}
                                                    onSelectId={handleOnSelectId}
                                                />
                                            </div>
                                        </div>
                                        <div className="hidden lg:block lg:col-span-1">{/* spacer */}</div>
                                    </div>

                                    <Footer
                                        // respNotice={createNotice}
                                        respNotice={false}
                                        setRespNotice={setCreateNotice}
                                        backLink="/fe/reports"
                                        footerType={footerType}
                                        onReActivate={handleReopenSale}  // O: command
                                        onDeactivate={handleCloseSale}   // R: command
                                        onActivate={handleRemoveSale}    // C: command
                                    />
                                    
                                </form>
                            ) : (
                                <div className="grid grid-cols-6 lg:grid-cols-5">
                                    <div className="col-span-6 lg:col-span-3">
                                        <div className="w-full flex justify-center">
                                            <div>{t('market_place_page.manage_face_cattle_side_no_content_title')}</div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </TabContent>

                    {/* Items tab */}
                    <TabContent id="tab2" activeTab={activeTab}>
                        <div className="text-sm"> 
                            {formattedItemData && formattedItemData.length > 0 ? (
                                <form autoComplete="off" className="w-full">
                                    <div className="overflow-hidden sm:rounded-md">
                                        <div className="col-span-6 lg:col-span-3">
                                            <div className="w-full flex justify-center">
                                                <EditDelTableDataView
                                                    tableData={formattedItemData}
                                                    idUsage={0}
                                                    useSerial={tableSettings.useSerial}
                                                    mapHash={{
                                                        "#": "#",
                                                        list_id: t('market_place_page.sale_id'),
                                                        category: t('market_place_page.manage_face_cattle_side_category'),
                                                        description: t('market_place_page.manage_face_item_side_item_description'),
                                                        created_at: t('market_place_page.manage_face_item_side_item_posted'),
                                                        region: t('market_place_page.manage_face_item_side_item_location'),
                                                        list_status: t('market_place_page.manage_face_item_side_item_status'),
                                                    }}
                                                    selectedId={selectedId}
                                                    onSelectId={handleOnItemSelectId}
                                                />
                                            </div>
                                        </div>
                                        <div className="hidden lg:block lg:col-span-1"></div>
                                    </div>

                                    <Footer
                                        // respNotice={createNotice}
                                        respNotice={false}
                                        setRespNotice={setCreateNotice}
                                        backLink="/fe/reports"
                                        footerType={footerType}
                                        onReActivate={handleReopenItemNeeded} 
                                        onDeactivate={handleCloseItemNeeded}   
                                        onActivate={handleRemoveItemNeeded}  
                                    />

                                </form>
                            ) : (
                                <div className="grid grid-cols-6 lg:grid-cols-5">
                                    <div className="col-span-6 lg:col-span-3">
                                        <div className="w-full flex justify-center">
                                            <div>{t('market_place_page.manage_face_item_side_no_content_title')}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </TabContent>
    
                </div>
            
            </div>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    draggable
                    pauseOnFocusLoss
                    theme="light"
                />        
                
        </div>
                   {/* Bottom Navigation */}
                   {/* <BottomNavigation /> */}
    </div>

);






// ------------------- end -------------------------------







};

export { CloseDealForm };
